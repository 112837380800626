import React, { useEffect } from "react";
import Text from "antd/lib/typography/Text";
import { Button, Col, Row, Table } from "antd";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import SelectCampaigns from "../PacingTab/SelectCampaigns";
import prepareExport from "./utils/prepareExport";
import { columnsObj } from "./utils";
import { formatNumber } from "../../../../../core/utils/campaigns";

const ContractTermsView = ({
  data,
  loading,
  refetch,
  total,
  pagination,
  setPagination,
  handleSorterChange,
  filterDataOptions,
  selectedCIDs,
  setSelectedCIDs,
  exportTableData,
  loadingExport,
  onExport,
  currentUser,
}) => {
  const isDemo = currentUser.isDemo;

  const columnRender = (key, value) => {
    if (
      key === columnsObj.budget.key ||
      key === columnsObj.cpm.key ||
      key === columnsObj.impressionsGoal.key
    ) {
      return <Text>{value ? formatNumber(value) : "0"}</Text>;
    }

    if (key === columnsObj.startDate.key || key === columnsObj.endDate.key) {
      return <Text>{value ? moment(value).format("MM/DD/YYYY") : "N/A"}</Text>;
    }

    return <Text>{value}</Text>;
  };

  const columns = Object.entries(columnsObj).map(([key, value]) => ({
    ...value,
    sorter: selectedCIDs.length > 0 ? false : value.sorter,
    render: value => columnRender(key, value),
  }));

  const handleTableChange = (_pagination, filters, sorter) => {
    const newPagination = {
      ..._pagination,
      current:
        pagination.pageSize !== _pagination.pageSize ? 1 : _pagination.current,
    };
    setPagination(newPagination);
    handleSorterChange(sorter.columnKey, sorter.order);
  };

  const onRefresh = () => {
    refetch();
  };

  useEffect(() => {
    if (exportTableData) {
      prepareExport(exportTableData);
    }
  }, [exportTableData]);

  const dataFormat = data.map((item, index) => {
    return {
      ...item,
      name: isDemo ? `Example Campaign ${index}` : item.name,
    };
  });

  return (
    <React.Fragment>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} md={10} xl={7}>
          <SelectCampaigns
            campaignDataOptions={filterDataOptions}
            selectedCIDs={selectedCIDs}
            setSelectedCIDs={setSelectedCIDs}
          />
        </Col>
        <Col xs={24} md={{ span: 5, offset: 4 }} xl={{ span: 3, offset: 11 }}>
          <Button
            style={{ width: "100%", display: "block" }}
            type="primary"
            shape="round"
            onClick={onRefresh}
            icon={<SyncOutlined width="100" />}
            loading={loading}
          >
            Refresh
          </Button>
        </Col>
        <Col xs={24} md={{ span: 5 }} xl={{ span: 3 }}>
          <Button
            style={{ width: "100%", display: isDemo ? "none" : "block" }}
            type="primary"
            shape="round"
            onClick={onExport}
            icon={<DownloadOutlined width="100" />}
            loading={loadingExport}
          >
            Export
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          style={{ width: "100%" }}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            ...pagination,
            showSizeChanger: total > 10,
            pageSizeOptions: [10, 20, 50, 100],
            total,
          }}
          dataSource={dataFormat}
          columns={columns}
          showSorterTooltip
        />
      </Row>
    </React.Fragment>
  );
};

export default ContractTermsView;
