import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Card,
  Typography,
  Space,
  Switch,
  message,
} from "antd";
import GlobalButton from "../../../../shared/globalStyling/Button";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  RiseOutlined,
  UndoOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

const EditPaidSearch = ({
  paidSearch = {}, //Paid Search Object to be editted
  tags = [], //Tags Paid Search Commerce
  onSubmit = () => {}, //Function to be called when the modal is submitted
}) => {
  const [visible, setVisible] = useState(false); //Modal Pop up
  const [selectedOrg, setSelectedOrg] = useState(null); //Currently Selected org to be launched by default use login org
  const [isRevenueByLocation, setIsRevenueByLocation] = useState(false); //Revenue by Location enabled
  const [isProcessWithGoogleAds, setIsProcessWithGoogleAds] = useState(false); //Process with Google Ads enabled. When enabled Google Analytics will be disabled
  const [isCommerceEnabled, setIsCommerceEnabled] = useState(false); //Commerce Enabled. When enabled Commerce Tag will be enabled
  const [form] = Form.useForm();

  //Set default values of the form
  const defaultSet = () => {};

  //Set default values of the form when currentOrgId changes
  useEffect(() => {
    if (paidSearch) {
      const config = paidSearch.config;
      setSelectedOrg(paidSearch);
      form.setFieldsValue({
        processWithGoogleAds:
          config && config.processWithGoogleAds
            ? config.processWithGoogleAds
            : false,
        googleAdsID:
          config && config.googleCustomerId ? config.googleCustomerId : null,
        googleAnalyticsID:
          config && config.googleViewId ? config.googleViewId : null,
        googleAnalyticsViewIdIsGA4:
          config && config.googleViewIdIsGa4 ? config.googleViewIdIsGa4 : false,
        hasLocationRevenue:
          config && config.hasLocationRevenue
            ? config.hasLocationRevenue
            : false,
        locationsKey: config && config.locationsKey ? config.locationsKey : [],
        bingAccountID:
          config && config.bingAccountId ? config.bingAccountId : null,
        bingCustomerID:
          config && config.bingCustomerId ? config.bingCustomerId : null,
        monthlyBudget:
          config && config.monthlyBudget ? config.monthlyBudget : null,
        hasCommerce: config && config.hasCommerce ? config.hasCommerce : false,
        commerceTags:
          config && config.commerceTags ? config.commerceTags.id : null,
      });

      if (config && config.hasCommerce) setIsCommerceEnabled(true);
      if (config && config.hasLocationRevenue) setIsRevenueByLocation(true);
      if (config && config.processWithGoogleAds)
        setIsProcessWithGoogleAds(true);
    }
  }, [form, paidSearch]);

  //Event Handler for showing launch modal
  const showModal = () => {
    setVisible(true);
  };

  //Event Handler for Updating Paid Search
  const handleOk = async () => {
    // Logic for handling the launch action
    form.validateFields().then(async values => {
      message.loading("Updating Paid Search");
      await onSubmit(values);
      message.destroy();
      message.success("Paid Search Updated");
      form.resetFields();
      defaultSet();
      setVisible(false);
    });
  };

  //Event Handler for Canceling Paid Search
  const handleCancel = () => {
    form.resetFields();
    defaultSet();
    setVisible(false);
  };
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col className="ant-col-xs-100 ant-col-md-flex-auto">
          <GlobalButton
            type="primary"
            onClick={showModal}
            icon={<EditOutlined />}
          >
            Edit Paid Search
          </GlobalButton>
        </Col>
      </Row>

      <Modal
        title="Edit Paid Search"
        visible={visible}
        onOk={async () => {
          await handleOk();
        }}
        onCancel={handleCancel}
        okText="Update"
        width={"80%"}
      >
        <Form form={form} layout="vertical" name={"launchPaidSearch"}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card title={"Channels"} bordered>
                <Row>
                  <Col span={24}>
                    <Text strong>Google</Text>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      label="Process only with Google Ads"
                      name={"processWithGoogleAds"}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={checked => {
                          if (checked) {
                            //If checked disable google analytics
                            form.setFieldsValue({
                              googleAnalyticsID: null,
                              googleAnalyticsViewIdIsGA4: null,
                            });
                          }
                          setIsProcessWithGoogleAds(checked);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Google Ads Customer ID"
                      name={"googleAdsID"}
                      rules={[
                        () => ({
                          validator(_, value) {
                            //If process with google ads is enabled then google ads customer id is required
                            if (isProcessWithGoogleAds) {
                              if (value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                `Please fill up Google Ads Customer ID`
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Search
                        enterButton={<UndoOutlined />}
                        placeholder="Customer ID"
                        onSearch={() => {
                          form.setFieldsValue({
                            googleAdsID:
                              selectedOrg.org.dataConfig.googleCustomerId,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Google Analytics View ID"
                      name={"googleAnalyticsID"}
                    >
                      <Input.Search
                        enterButton={<UndoOutlined />}
                        placeholder="Analytics ID"
                        onChange={e => {
                          console.log(e);
                        }}
                        onSearch={() => {
                          form.setFieldsValue({
                            googleAnalyticsID:
                              selectedOrg.org.dataConfig.googleAnalytics &&
                              selectedOrg.org.dataConfig.googleAnalytics.viewId
                                ? selectedOrg.org.dataConfig.googleAnalytics
                                    .viewId
                                : null,
                            googleAnalyticsViewIdIsGA4:
                              selectedOrg.org.dataConfig.googleAnalytics &&
                              selectedOrg.org.dataConfig.googleAnalytics.isGA4
                                ? selectedOrg.org.dataConfig.googleAnalytics
                                    .isGA4
                                : false,
                          });
                        }}
                        disabled={isProcessWithGoogleAds}
                      />
                    </Form.Item>
                    <Space size="small" direction="horizontal">
                      <Typography.Text>UA</Typography.Text>
                      <Form.Item
                        name={"googleAnalyticsViewIdIsGA4"}
                        valuePropName="checked"
                        noStyle
                      >
                        <Switch disabled={isProcessWithGoogleAds} />
                      </Form.Item>
                      <Typography.Text>GA4</Typography.Text>
                    </Space>
                  </Col>
                  <Col xs={6} md={{ offset: 6 }}>
                    <Form.Item
                      label="Enable Revenue by Location"
                      name={"hasLocationRevenue"}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={checked => {
                          setIsRevenueByLocation(checked);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={"Locations"} name={"locationsKey"}>
                      <Select
                        mode="tags"
                        style={{ width: `100%` }}
                        allowClear={true}
                        disabled={!isRevenueByLocation}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col xs={24}>
                        <Text strong>Bing</Text>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label="Customer ID" name={"bingCustomerID"}>
                          <Input.Search
                            enterButton={<UndoOutlined />}
                            placeholder="Customer ID"
                            onSearch={() => {
                              form.setFieldsValue({
                                bingCustomerID:
                                  selectedOrg.org.dataConfig.bing &&
                                  selectedOrg.org.dataConfig.bing.customerId
                                    ? selectedOrg.org.dataConfig.bing.customerId
                                    : null,
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item label="Account ID" name={"bingAccountID"}>
                          <Input.Search
                            enterButton={<UndoOutlined />}
                            placeholder="Account ID"
                            onSearch={() => {
                              form.setFieldsValue({
                                bingAccountID:
                                  selectedOrg.org.dataConfig.bing &&
                                  selectedOrg.org.dataConfig.bing.accountId
                                    ? selectedOrg.org.dataConfig.bing.accountId
                                    : null,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <Text strong>Commerce</Text>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name={"hasCommerce"}
                          label={"Enable Commerce Tracking"}
                          valuePropName="checked"
                        >
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={checked => {
                              if (!checked) {
                                form.setFieldsValue({
                                  commerceTags: null,
                                });
                              }
                              setIsCommerceEnabled(checked);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"commerceTags"}
                          label={"Commerce Tag"}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator() {
                                if (getFieldValue("hasCommerce")) {
                                  if (getFieldValue("commerceTags")) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    `Please Select a Commerce Tag`
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            allowClear={true}
                            placeholder="Select Commerce Tag"
                            showSearch={true}
                            disabled={!isCommerceEnabled}
                            filterOption={(input, option) => {
                              if (
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              )
                                return true;
                            }}
                            options={
                              tags &&
                              tags.map(tag => {
                                return { value: tag.id, label: tag.name };
                              })
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.label
                                .toLowerCase()
                                .localeCompare(optionB.label.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.Item
                  name="hasChannels"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        const hasGoogle =
                          getFieldValue("googleAdsID") ||
                          getFieldValue("googleAnalyticsID")
                            ? true
                            : false;
                        const hasBing =
                          getFieldValue("bingCustomerID") ||
                          getFieldValue("bingAccountID")
                            ? true
                            : false;
                        const hasCommerce =
                          getFieldValue("hasCommerce") &&
                          getFieldValue("commerceTags")
                            ? true
                            : false;

                        if (hasGoogle || hasBing || hasCommerce) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          `Please fill up at least one channel`
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={
                  <Row type="flex" gutter={10} align="middle">
                    <Col>
                      <RiseOutlined />
                      <span> Budget</span>
                    </Col>
                  </Row>
                }
              >
                <Row>
                  <Text type="secondary">
                    The amount you set as the overall budget will be the maximum
                    amount that the campaign will spend over the course of its
                    flight dates.
                  </Text>
                </Row>
                <Row>
                  <Form.Item
                    name={"monthlyBudget"}
                    rules={[{ required: true, message: "Budget Required" }]}
                  >
                    <Input addonBefore="$USD" value={0} allowClear />
                  </Form.Item>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default EditPaidSearch;
