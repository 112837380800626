import React from "react";
import { graphql } from "react-apollo";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";
import EditorialMainController from "./EditorialMainController";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import LIST_ORGS_SHARING from "../../../GraphQl/Queries/LIST_ORGS_SHARING";
import TAG_ORGS from "../../../GraphQl/Queries/TAG_ORGS";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import EDITORIAL_CONNECTION from "../../../GraphQl/Queries/EDITORIAL_CONNECTION";
import CREATE_EDITORIAL from "../../../GraphQl/Mutations/CREATE_EDITORIAL";
import EDIT_EDITORIAL_SHARING from "../../../GraphQl/Mutations/EDIT_EDITORIAL_SHARING";
import EDITORIALS from "../../../GraphQl/Queries/EDITORIALS";
import GET_LOCATIONS from "../../../GraphQl/Queries/GET_LOCATIONS";
import ATTRIBUTION_AUDIENCES_ORG_CONFIG from "../../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_ORG_CONFIG";
import { cloneDeep } from "lodash";

const EditorialMainLoader = props => {
  const { children, currentUser, location, match, history } = props;
  const currentOrgId = currentUser.role.org.id;
  const pageNumber = match.params.pageNumber;

  const params = new URLSearchParams(location.search);
  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  const publisher = params.get("publisher");

  if (isNaN(pageNumber) || Number(pageNumber) < 1)
    location.search
      ? history.push(`editorial/main/1${location.search}`)
      : history.push("editorial/main/1");

  const search = params.get("search");
  const where = {
    AND: [
      {
        clicksTag: {
          id_not: null,
        },
      },
    ],
    OR: [
      {
        sharedOrgs_some: {
          org: {
            id: currentOrgId,
          },
        },
      },
      {
        sharedOrgs_some: {
          org: {
            parentOrg_some: { id: currentOrgId },
          },
        },
      },
    ],
  };

  let whereFilter = cloneDeep(where);
  if (search) {
    whereFilter["AND"].push({ name_contains: search });
  }

  if (publisher) {
    whereFilter["AND"].push({
      transactionsTag: {
        orgs_some: {
          id: publisher,
        },
      },
    });
  }
  //Query for the table data of editorials
  const { data, loading, refetch } = useQuery(EDITORIAL_CONNECTION, {
    variables: {
      //first: pageSize,
      //skip: (Number(pageNumber) - 1) * pageSize,
      where: whereFilter,
      orderBy: "name_ASC",
    },
    fetchPolicy: "no-cache",
  });

  const { data: allEditorials, loading: loadingAllEditorials } = useQuery(
    EDITORIALS,
    {
      variables: {
        where,
      },
      fetchPolicy: "no-cache",
    }
  );

  //Tags option for Editroials Creation
  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  //Query for the orgs that are sharing the current org
  const { data: orgsSharing, loading: orgsLoading } = useQuery(
    LIST_ORGS_SHARING,
    {
      variables: {
        parentid: currentOrgId,
      },
    }
  );

  // Query for locations for funnel creation
  const { loading: loadingLocation, data: locationData } = useQuery(
    GET_LOCATIONS,
    {
      variables: { id: currentOrgId },
    }
  );

  // Query for the location groups
  const { loading: loadingAudiences, data: attributionAudiences } = useQuery(
    ATTRIBUTION_AUDIENCES_ORG_CONFIG,
    {
      variables: { TYPE: "ATTRIBUTION", ORGID: currentOrgId },
    }
  );

  const [createEditorial] = useMutation(CREATE_EDITORIAL);
  const [updateEditorialSharing] = useMutation(EDIT_EDITORIAL_SHARING);

  if (
    eventTagsLoading ||
    orgsLoading ||
    loading ||
    !data ||
    loadingAllEditorials ||
    loadingLocation ||
    loadingAudiences
  )
    return <OverlaySpinner />;

  const total = data.full.aggregate.count;
  return (
    <EditorialMainController
      {...props}
      data={data.editorialsConnection.edges.map(({ node }) => node)}
      eventsTargets={eventTags.eventsTargets}
      isSelfService={currentUser.permission.isSelfService}
      orgsSharing={orgsSharing.orgs}
      current={Number(pageNumber)}
      pageSize={pageSize}
      total={total}
      createEditorial={createEditorial}
      refetch={refetch}
      updateEditorialSharing={updateEditorialSharing}
      allEditorials={allEditorials.editorials}
      selectedPublisher={publisher}
      currentOrgId={currentOrgId}
      locationData={locationData}
      attributionAudiences={attributionAudiences}
    >
      {children}
    </EditorialMainController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(EditorialMainLoader));
