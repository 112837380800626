import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Input,
  Button,
  Tabs,
  Tag,
  Typography,
  Space,
  Modal,
  Form,
  message,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MapDrawing from "../../../shared/maps/MapDrawing";
import { MapConfig } from "../../../shared/maps/configs/mapConfigs";

const { Search } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;

const SelectRetailStoresModal = ({
  visible,
  onClose,
  filteredLocations,
  filteredAudiences,
  onSelectStores,
  createLocation,
  google,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [
    isCreateLocationModalVisible,
    setIsCreateLocationModalVisible,
  ] = useState(false);
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [hasOverlay, setHasOverlay] = useState(false);
  const [initialPosition, setInitialPosition] = useState(null);

  const locationColumns = [
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Street Address",
      dataIndex: "street",
      key: "street",
      render: (text, record) =>
        `${text}, ${record.city} ${record.state}, ${record.zip}`,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => new Date(text).toLocaleString(),
    },
  ];

  const locationGroupColumns = [
    {
      title: "Region Group Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Locations",
      dataIndex: "geoTargets",
      key: "locations",
      render: function(geoTargets) {
        if (!geoTargets || !Array.isArray(geoTargets)) {
          return null;
        }
        return (
          <Space wrap>
            {geoTargets.map(gt => (
              <Tag key={gt.id} color="blue">
                {gt.name}
              </Tag>
            ))}
          </Space>
        );
      },
    },
  ];

  const handleSearch = value => {
    setSearchText(value);
  };

  const filteredLocationData = Array.isArray(filteredLocations)
    ? filteredLocations.filter(
        item =>
          item &&
          item.locationKey && // Only include items with a locationKey
          ((item.name &&
            typeof item.name === "string" &&
            item.name.toLowerCase().includes(searchText.toLowerCase())) ||
            (item.street &&
              typeof item.street === "string" &&
              item.street.toLowerCase().includes(searchText.toLowerCase())))
      )
    : [];

  const filteredAudienceData = Array.isArray(filteredAudiences)
    ? filteredAudiences
        .filter(
          item =>
            item &&
            item.node &&
            item.node.name &&
            typeof item.node.name === "string" &&
            item.node.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .map(item => item.node)
    : [];

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const handleAddSelections = () => {
    const selectedStores =
      activeTab === "1"
        ? filteredLocationData.filter(location =>
            selectedRowKeys.includes(location.id)
          )
        : filteredAudienceData.filter(group =>
            selectedRowKeys.includes(group.id)
          );
    onSelectStores(selectedStores, activeTab === "2");
  };

  const FirstFormPage = ({ form }) => {
    return (
      <>
        <Form.Item
          label="Location Name"
          name="name"
          extra="Required name given to this location"
          rules={[
            {
              required: true,
              message: "Please input your Business name!",
            },
          ]}
        >
          <Input placeholder="Enter retail store name" />
        </Form.Item>
        <Form.Item
          label="Location Description"
          name="description"
          extra="Optional: Description for this location"
        >
          <Input placeholder="Optional description" />
        </Form.Item>
        <Form.Item
          label="Location Key"
          name="locationKey"
          extra="Optional: Shopping cart URL string that identifies a retail location"
        >
          <Input placeholder="Location Key" />
        </Form.Item>
        <Form.Item
          label="Street Address"
          name="formattedAddress"
          extra="Search and then select the address of this location"
          rules={[
            {
              required: true,
              message: "Address is required!",
            },
          ]}
        >
          <input
            type="input"
            placeholder="Address"
            name="formattedAddress"
            id="search-input-tl"
            className="ant-input"
            onFocus={e => {
              e.target.setAttribute("autocomplete", "no");
            }}
          />
        </Form.Item>
        <Row>
          <Col xs={24} xl={9} style={{ padding: 5 }}>
            <Form.Item label="City" name="city">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} xl={9} style={{ padding: 5 }}>
            <Form.Item label="Zip" name="zip">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6} style={{ padding: 5 }}>
            <Form.Item label="State" name="state">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const SecondFormPage = ({ form }) => {
    return (
      <>
        <Col xs={24} lg={24}>
          <MapDrawing
            inputPlaceholder={"Search Retail Location..."}
            zoom={17}
            overlayChanged={val => {
              const overlayValue = Array.isArray(val) ? val : [val];
              console.log("Overlay changed:", overlayValue); // Debug log
              form.setFieldsValue({ inProgressOverlay: overlayValue });
              setHasOverlay(overlayValue.length > 0);
              console.log(
                "Form values after overlay change:",
                form.getFieldsValue()
              ); // Debug log
            }}
            initialLocation={initialPosition}
            toolOptions={{
              fillColor: "#BCDCF9",
              fillOpacity: 0.5,
              strokeWeight: 3,
              strokeColor: "#57ACF9",
              clickable: true,
              editable: true,
              zIndex: 1,
            }}
            styles={{
              map: {
                height: "500px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              },
              mapContainer: {
                height: "510px",
                width: "100%",
                position: "relative",
              },
            }}
            changeLocation={place => {
              if (place && place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                form.setFieldsValue({
                  lat: lat,
                  lng: lng,
                });
              }
            }}
            drawing={true}
            isSearchBarVisible={false}
            mapType={"satellite"}
            shapes={[]}
          />
        </Col>
      </>
    );
  };

  const onFinish = values => {
    console.log("Form submitted with values:", values); // Debug log
    if (!hasOverlay) {
      message.error("Please draw an overlay on the map before submitting.");
      return;
    }
    const overlayData = form.getFieldValue("inProgressOverlay");
    console.log("Overlay data on submit:", overlayData); // Debug log
    createLocation({ ...values, inProgressOverlay: overlayData })
      .then(() => {
        message.success("Location created successfully");
        setIsCreateLocationModalVisible(false);
        form.resetFields();
        setCurrentStep(1);
      })
      .catch(error => {
        message.error("Failed to create location: " + error.message);
      });
  };

  const handleCreateLocationCancel = () => {
    setIsCreateLocationModalVisible(false);
    form.resetFields();
    setCurrentStep(1);
  };

  useEffect(() => {
    if (isCreateLocationModalVisible) {
      const input = document.getElementById("search-input-tl");
      if (input && google && google.maps && google.maps.places) {
        input.addEventListener("keydown", e => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        });

        var options = {
          componentRestrictions: MapConfig.componentRestrictions,
        };

        const searchBox = new google.maps.places.Autocomplete(input, options);

        searchBox.addListener("place_changed", () => {
          const place = searchBox.getPlace();

          if (!place || !place.geometry || !place.geometry.location) {
            message.error("Error: Place not found!");
            return;
          }

          updateFormFields(place);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateLocationModalVisible, google, form]);

  const updateFormFields = place => {
    const { geometry } = place;
    let address = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address = `${component.long_name} ${address}`;
          break;
        case "route":
          address += component.short_name;
          break;
        case "postal_code":
          zip = `${component.long_name}${zip}`;
          break;
        case "postal_code_suffix":
          zip = `${zip}-${component.long_name}`;
          break;
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.long_name;
          break;
        case "country":
          country = component.short_name;
          break;
        default:
          console.warn(`Unknown component type: ${componentType}`);
          break;
      }
    }

    const formattedState = state.replace(/\s+/g, "_").toUpperCase();
    const formattedCountry = country === "US" ? "USA" : country;

    form.setFieldsValue({
      formattedAddress: place.formatted_address,
      street: address,
      city: city,
      state: formattedState,
      zip: zip,
      country: formattedCountry,
    });

    if (geometry && geometry.viewport) {
      const { south, north, east, west } = geometry.viewport.toJSON();

      const calculatedLat = Number(((north + south) / 2).toFixed(7));
      const calculatedLng = Number(((east + west) / 2).toFixed(7));

      setInitialPosition({
        latitude: calculatedLat,
        longitude: calculatedLng,
      });

      form.setFieldsValue({
        lat: calculatedLat,
        lng: calculatedLng,
      });
    }
  };

  const renderCreateLocationModal = () => (
    <Modal
      visible={isCreateLocationModalVisible}
      title={currentStep === 1 ? "CREATE LOCATION" : "LOCATION BOUNDARY AREA"}
      onCancel={handleCreateLocationCancel}
      footer={null}
      bodyStyle={currentStep === 2 ? { padding: 0 } : {}}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        {currentStep === 1 ? (
          <FirstFormPage form={form} />
        ) : (
          <SecondFormPage form={form} />
        )}
        <Form.Item name="inProgressOverlay" hidden={true}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="lat" hidden={true}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="lng" hidden={true}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {currentStep > 1 && (
              <Button
                onClick={() => setCurrentStep(currentStep - 1)}
                style={{ marginRight: "10px" }}
              >
                Back
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={!form.getFieldValue("formattedAddress")}
              >
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button type="primary" htmlType="submit" disabled={!hasOverlay}>
                Submit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );

  const renderTabContent = tabKey => {
    const isLocationsTab = tabKey === "1";
    return (
      <>
        {isLocationsTab ? (
          <Row
            gutter={16}
            align="middle"
            justify="space-between"
            style={{ marginBottom: 16, marginTop: 16 }}
          >
            <Col>
              <Row align="middle" gutter={8}>
                <Col>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    RETAIL STORE LOCATIONS
                  </Title>
                </Col>
                <Col>
                  <Tooltip title="Make sure your location has a valid Location Key">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={16}>
                {/* TEMP: Hide Add Location */}
                {/* <Button
                  style={{ marginRight: 16 }}
                  disabled={selectedRowKeys.length === 0}
                >
                  Add to Location Group
                </Button>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={showCreateLocationModal}
                >
                  Add Location
                </Button> */}
                <Search
                  placeholder="Search email audiences"
                  onSearch={value => handleSearch(value)}
                  style={{ width: "250px", marginRight: 16 }}
                  allowClear
                />
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={handleAddSelections}
                  disabled={selectedRowKeys.length === 0}
                >
                  Add Selections
                </Button>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16, marginTop: 16 }}
          >
            <Col>
              <Title level={5}>RETAIL STORE LOCATION GROUPS</Title>
            </Col>
            <Col>
              <Search
                placeholder="Search email audiences"
                onSearch={value => handleSearch(value)}
                style={{ width: 250, marginRight: 16 }}
                allowClear
              />
              <Button
                type="primary"
                style={{ marginRight: 16 }}
                onClick={handleAddSelections}
                disabled={selectedRowKeys.length === 0}
              >
                Add Selections
              </Button>
            </Col>
          </Row>
        )}
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: newSelectedRowKeys =>
              setSelectedRowKeys(newSelectedRowKeys),
          }}
          columns={isLocationsTab ? locationColumns : locationGroupColumns}
          dataSource={
            isLocationsTab ? filteredLocationData : filteredAudienceData
          }
          rowKey="id"
        />
      </>
    );
  };

  return (
    <Card title="RETAIL STORES" bodyStyle={{ padding: 0 }}>
      <Tabs tabPosition="left" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Locations" key="1" disabled={selectedRowKeys.length > 0}>
          {renderTabContent("1")}
        </TabPane>
        <TabPane
          tab="Location Groups"
          key="2"
          disabled={selectedRowKeys.length > 0}
        >
          {renderTabContent("2")}
        </TabPane>
      </Tabs>
      {renderCreateLocationModal()}
    </Card>
  );
};

export default SelectRetailStoresModal;
