import React, { useState, useEffect } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import SeoOnboardingDocs from "./components/Docs";
import UtilsContent from "./components/UtilsContent";
import FiltersBar from "./components/FiltersBar";
import MainTable from "./components/MainTable";
import SharedOrgsModal from "./components/SharedOrgsModal";
import { isNil } from "lodash";
import { message } from "antd";

const SeoMainView = ({
  seoCampaigns = [],
  isShowOnboardingDocs = false,
  childOrgsLoading = true,
  getSharedDetails = () => null,
  childOrgs = [],
  sharedOrgsLoading = true,
  currentOrgId,
  sharedOrgsDetails,
  onSubmitSharedModal = () => null,
  orgCustomization,
}) => {
  //Title shown on browser tab
  document.title = "SEO Main View";
  const { currencyFormat } = orgCustomization;
  const [isOpenSharing, setIsOpenSharing] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toBeSavedData, setToBeSavedData] = useState([]);
  const [sharedOrgs, setSharedOrgs] = useState([]);

  const onRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    if (!isNil(sharedOrgsDetails)) {
      const modifiedSharedOrgs =
        !isNil(sharedOrgsDetails.orgSeoSummaries) &&
        sharedOrgsDetails.orgSeoSummaries.map(o => {
          const savedValue = [];
          if (o.sharedOrgs.length) {
            o.sharedOrgs.forEach(s => {
              savedValue.push(s.id);
            });
          }
          const defaultDisabled = [o.org.id, currentOrgId];
          o.displayValues = [...defaultDisabled, ...savedValue];
          o.displayValues = [...new Set(o.displayValues)];
          o.values = savedValue;
          return o;
        });
      setSharedOrgs(modifiedSharedOrgs);
      setToBeSavedData(modifiedSharedOrgs);
    }
  }, [currentOrgId, sharedOrgsDetails]);

  const onCancelSharedModal = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSharedOrgs([]);
    setToBeSavedData([]);
    setIsOpenSharing(false);
  };

  return (
    <React.Fragment>
      <SharedOrgsModal
        modalToggle={isOpenSharing}
        childOrgs={childOrgs}
        sharedOrgsLoading={sharedOrgsLoading}
        sharedOrgs={sharedOrgs}
        selectedRows={selectedRows}
        currentOrgId={currentOrgId}
        onCancel={onCancelSharedModal}
        onSubmit={async () => {
          message.loading(`Sharing in progress ...`);
          await onSubmitSharedModal(toBeSavedData, sharedOrgs);
          message.success(`Sharing Complete`);
          onCancelSharedModal();
        }}
        setToBeSavedData={setToBeSavedData}
        toBeSavedData={toBeSavedData}
      />
      <PageHeaderLayout
        title={"SEO"}
        titleIcon={<UsergroupAddOutlined style={mainTitleIcon} />}
        titleRightIcon={isShowOnboardingDocs && <SeoOnboardingDocs />}
      />
      <PageContentLayout>
        <PageHeaderLayout
          title={"Domains"}
          rightContent={
            <UtilsContent
              childOrgsLoading={childOrgsLoading}
              selectedRowKeys={selectedRowKeys}
              setSharedOrgsToggle={setIsOpenSharing}
              getSharedDetails={getSharedDetails}
            />
          }
        />
        <FiltersBar />
        <MainTable
          seoCampaigns={seoCampaigns}
          onRowChange={onRowChange}
          selectedRowKeys={selectedRowKeys}
          currencyFormat={currencyFormat}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SeoMainView;
