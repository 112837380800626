/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";
import { Tooltip } from "antd";

// Ant Design
import { Row, Col, Space, Table, Typography, Select, Input } from "antd";

const { Title, Text } = Typography;
const { TextArea } = Input;

const AudienceDetails = props => {
  let { audienceDetails, audienceState, setAudienceState } = props;
  const { name, createdAt, createdBy, reachCount } = audienceDetails;
  const { name: createdByName } = createdBy;

  // Initialize the state with the audience details
  useEffect(() => {
    setAudienceState(audienceDetails);
  }, []);

  const columns = [
    {
      title: "Published On",
      dataIndex: "publishedOn",
      key: "pubishedOn",
      render: text => <p>{text}</p>,
    },
    {
      title: "DSP",
      dataIndex: "dsp",
      key: "dsp",
      render: text => <p>{text}</p>,
    },
    {
      title: "Data CPM",
      dataIndex: "dataCpm",
      key: "dataCpm",
      render: text => <p>{text}</p>,
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: "25px" }}>
        <Title level={5}>Details </Title>
      </Row>
      <Space></Space>
      <Row style={{ marginBottom: "25px" }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Text type="secondary">Audience Name</Text>
          <br />
          <div
            style={{
              width: "175px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={name}>
              <span style={{ cursor: "pointer" }}>{name}</span>
            </Tooltip>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Text type="secondary">Reach</Text>
          <br />
          <Text>{reachCount}</Text>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} span={6}>
          <Text type="secondary">Authored</Text>
          <br />
          <Text>{createdByName}</Text>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} span={6}>
          <Text type="secondary">Created On</Text>
          <br />
          <Text>{moment(createdAt).format("MM/DD/YYYY hh:mma")}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: "25px" }} gutter={8}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Text type="secondary">Description</Text>
          <br />
          <TextArea
            value={audienceState.description}
            onChange={e => {
              const newValue = e.target.value;
              setAudienceState({ ...audienceState, description: newValue });
            }}
            rows={4}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Text type="secondary">Tags</Text>
          <br />
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Select or create tags"
            value={audienceDetails.tags}
            onChange={value => {
              setAudienceState({ ...audienceState, tags: value });
            }}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col span={24}>
          <Title level={5}>Publishing History </Title>
          <br />
          <Table columns={columns}></Table>
        </Col>
      </Row>
    </>
  );
};

export default AudienceDetails;
