import React, { useState, useEffect } from "react";
import { cloneDeep, unset } from "lodash";
import moment from "moment";
import {
  computeCTR,
  computeCPC,
  computeROAS,
} from "../../../utils/paidSearch/campaigns";

import {
  formatWholeNumber,
  formatNumber,
  formatCurrency2SigFig,
} from "../../../utils/campaigns";
import { notification } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";

const checkHasGoogle = paidSearchSummary => {
  return (
    (paidSearchSummary.paidSearchSummary.config &&
      paidSearchSummary.paidSearchSummary.config.googleCustomerId &&
      paidSearchSummary.paidSearchSummary.overallData) ||
    (paidSearchSummary.paidSearchSummary.org.dataConfig.googleCustomerId &&
      paidSearchSummary.paidSearchSummary.overallData)
  );
};

const checkHasBing = (isLatest, paidSearchSummary) => {
  return (
    (paidSearchSummary.paidSearchSummary.config &&
      paidSearchSummary.paidSearchSummary.config.bingAccountId &&
      paidSearchSummary.paidSearchSummary.config.bingCustomerId &&
      paidSearchSummary.paidSearchSummary.overallBing) ||
    (paidSearchSummary.paidSearchSummary.org.dataConfig.bing &&
      paidSearchSummary.paidSearchSummary.org.dataConfig.bing.accountId &&
      paidSearchSummary.paidSearchSummary.org.dataConfig.bing.customerId &&
      paidSearchSummary.paidSearchSummary.overallBing)
  );
};

const PaidSearchDetailsController = ({
  paidSearchSummary,
  possibleFields,
  defaultColumns,
  loadingCampaigns,
  orgConfig,
  total,
  current,
  orgId,
  pageSize,
  updateConfigMutation,
  search,
  selectedStartDate,
  selectedEndDate,
  getCampaignsByLocations,
  hasDateParams,
  selectedSubTab,
  reports,
  reprocessPaidSearch,
  refetch,
  ...props
}) => {
  const [googleReport, setGoogleReport] = useState(reports || []);
  const [revenueLocations, setGoogleRevenueLocations] = useState(
    reports ? reports.overallLocations : null
  );
  const [bing, setReportBing] = useState(reports ? reports.overallBing : null);
  const [reportBingCampaigns, setReportBingCampaigns] = useState(
    reports ? reports.paidSearchBingCampaigns : []
  );
  const [reportCampaigns, setGoogleReportCampaigns] = useState(
    reports ? reports.paidSearchCampaigns : []
  );

  useEffect(() => {
    if (reports) {
      setGoogleReport(reports);
      setGoogleRevenueLocations(reports.overallLocations);
      setReportBing(reports.overallBing);
      setReportBingCampaigns(reports.paidSearchBingCampaigns);
      setGoogleReportCampaigns(reports.paidSearchCampaigns);
    }
  }, [reports]);

  let dataConfig = {};

  if (props.currentUser.defaultRole.defaultRole) {
    dataConfig = props.currentUser.defaultRole.defaultRole.org.dataConfig;
  } else if (props.currentUser.defaultRole) {
    dataConfig = props.currentUser.defaultRole.org.dataConfig;
  } else {
    dataConfig = {};
  }

  const isPaidSearchLatest = paidSearchSummary.paidSearchSummary.config
    ? true
    : false;

  const hasGoogle = checkHasGoogle(paidSearchSummary);
  const hasBing = checkHasBing(isPaidSearchLatest, paidSearchSummary);

  const hasCommerce =
    paidSearchSummary.paidSearchSummary.overallCommerce &&
    paidSearchSummary.paidSearchSummary.overallCommerce.id &&
    paidSearchSummary.paidSearchSummary.config &&
    paidSearchSummary.paidSearchSummary.config.hasCommerce;
  const isMultiChannel =
    [hasGoogle, hasBing, hasCommerce].filter(Boolean).length >= 2
      ? true
      : false;

  const generateReport = key => {
    let data = [];
    const priceVisibleExclude = ["ROAS", "CPC", "Spend"];
    switch (key) {
      case "byDate":
        data = googleReport.overallData.byDate.map(d => {
          const row = {
            Date: moment(d.xAxis).format("M/D/YYYY"),
            Clicks: formatWholeNumber(d.clicks),
            Impressions: formatWholeNumber(d.impressions),
            Transactions: formatWholeNumber(d.conversions),
            CTR: `${formatNumber(d.ctr)}%`,
            CPC: formatCurrency2SigFig(d.cpc),
            Revenue: formatCurrency2SigFig(d.revenue),
            Spend: formatCurrency2SigFig(d.spend),
            ROAS: `${formatNumber(d.roas)}%`,
          };

          !orgConfig.priceVisible &&
            priceVisibleExclude.forEach(exclude => {
              unset(row, exclude);
            });
          return row;
        });
        const totalRow = {
          Date: `Total`,
          Clicks: formatWholeNumber(googleReport.overallData.clicks),
          Impressions: formatWholeNumber(googleReport.overallData.impressions),
          Transactions: formatWholeNumber(googleReport.overallData.conversions),
          CTR: `${formatNumber(googleReport.overallData.ctr)}%`,
          CPC: formatCurrency2SigFig(googleReport.overallData.cpc),
          Revenue: formatCurrency2SigFig(googleReport.overallData.revenue),
          Spend: formatCurrency2SigFig(googleReport.overallData.spend),
          ROAS: `${formatNumber(googleReport.overallData.roas)}%`,
        };

        !orgConfig.priceVisible &&
          priceVisibleExclude.forEach(exclude => {
            unset(totalRow, exclude);
          });
        data.push(totalRow);
        break;
      case "byCampaign":
        const campaigns = cloneDeep(reportCampaigns);
        data = campaigns.map(d => {
          const cByDate = d.data.byDate;
          const filterByDate = cByDate.filter(obj => {
            if (typeof obj.date === "string") obj.date = moment(obj.date);
            return (
              obj.date >= moment(selectedStartDate) &&
              obj.date <= moment(selectedEndDate)
            );
          });

          const cData = {
            Campaign: d.name,
            "Dates Ranges": `${moment(selectedStartDate)
              .utc()
              .format("MM/DD/YYYY")} - ${moment(selectedEndDate)
              .utc()
              .format("MM/DD/YYYY")}`,
            Clicks: 0,
            Impressions: 0,
            Transactions: 0,
            CTR: 0,
            CPC: 0,
            Revenue: 0,
            Spend: 0,
            ROAS: 0,
          };
          if (filterByDate && filterByDate.length > 0) {
            let consolidatedCount = filterByDate.reduce((conso, obj) => ({
              clicks: conso.clicks + obj.clicks,
              impressions: conso.impressions + obj.impressions,
              keywords: conso.keywords + obj.keywords,
              locations: conso.locations + obj.locations,
              conversions: conso.conversions + obj.conversions,
              cost: conso.cost + obj.cost,
              revenue: conso.revenue + obj.revenue,
            }));

            const spend = consolidatedCount.cost;
            const ctr = computeCTR(
              consolidatedCount.clicks,
              consolidatedCount.impressions
            );
            const cpc = computeCPC(
              consolidatedCount.cost,
              consolidatedCount.clicks
            );
            const roas = computeROAS(consolidatedCount.revenue, spend);

            cData.Clicks = consolidatedCount.clicks;
            cData.Impressions = consolidatedCount.impressions;
            cData.Transactions = consolidatedCount.conversions;
            cData.Spend = consolidatedCount.cost;
            cData.Revenue = consolidatedCount.revenue;
            cData.CTR = ctr;
            cData.CPC = cpc;
            cData.ROAS = roas;
          }

          cData.Clicks = formatWholeNumber(cData.Clicks);
          cData.Impressions = formatWholeNumber(cData.Impressions);
          cData.Transactions = formatWholeNumber(cData.Transactions);
          cData.Spend = formatCurrency2SigFig(cData.Spend);
          cData.Revenue = formatCurrency2SigFig(cData.Revenue);
          cData.CTR = `${formatNumber(cData.CTR)}%`;
          cData.CPC = formatCurrency2SigFig(cData.CPC);
          cData.ROAS = `${formatNumber(cData.ROAS)}%`;

          !orgConfig.priceVisible &&
            priceVisibleExclude.forEach(exclude => {
              unset(cData, exclude);
            });

          return cData;
        });

        break;
      case "byRevenueLoc":
        const campaignsRevLoc = cloneDeep(reports.paidSearchCampaigns);
        data = revenueLocations.byDate.map(rev => {
          const campaignsFiltered = campaignsRevLoc.filter(cmp => {
            return cmp.name.toLowerCase().includes(rev.location.toLowerCase());
          });
          const campaignKPI = campaignsFiltered.reduce(
            (cmpPrev, cmpCurr) => {
              const cmpSingleKPI = cmpCurr.data.byDate.reduce(
                (prev, curr) => {
                  if (
                    moment(curr.date) >= moment(rev.date).startOf("M") &&
                    moment(curr.date) <= moment(rev.date).endOf("M")
                  ) {
                    prev.spend += parseFloat(curr.spend);
                    prev.transactions += parseFloat(curr.conversions);
                  }
                  return prev;
                },
                { spend: 0, transactions: 0 }
              );
              return {
                spend: (cmpPrev.spend += cmpSingleKPI.spend),
                transactions: (cmpPrev.transactions +=
                  cmpSingleKPI.transactions),
              };
            },
            { spend: 0, transactions: 0 }
          );
          return {
            MonthYear: moment(rev.date).format("YYYY MMMM"),
            Location: rev.location,
            Revenue: parseFloat(rev.revenue).toFixed(2),
            Spend: parseFloat(campaignKPI.spend).toFixed(2),
            Transactions: parseInt(campaignKPI.transactions),
            ROAS: `${computeROAS(rev.revenue, campaignKPI.spend).toFixed(2)}%`,
          };
        });
        break;
      case "byRevenueLocByDate":
        data = revenueLocations.byDay.map(rev => {
          return {
            MonthYear: moment(rev.date).format("MMM DD YYYY"),
            Location: rev.location,
            Revenue: parseFloat(rev.revenue).toFixed(2),
            Spend: parseFloat(rev.cost).toFixed(2),
            Transactions: parseInt(rev.conversions),
            ROAS: `${parseFloat(rev.roas).toFixed(2)}%`,
          };
        });
        break;
      case "byDateBing":
        data = bing.byDate.map(d => {
          const row = {
            Date: moment(d.xAxis).format("M/D/YYYY"),
            Clicks: formatWholeNumber(d.clicks),
            Impressions: formatWholeNumber(d.impressions),
            Transactions: formatWholeNumber(d.conversions),
            CTR: `${formatNumber(d.ctr)}%`,
            CPC: formatCurrency2SigFig(d.cpc),
            Revenue: formatCurrency2SigFig(d.revenue),
            Spend: formatCurrency2SigFig(d.spend),
            ROAS: `${formatNumber(d.roas)}%`,
          };

          !orgConfig.priceVisible &&
            priceVisibleExclude.forEach(exclude => {
              unset(row, exclude);
            });
          return row;
        });
        const totalRowBingByDate = {
          Date: `Total`,
          Clicks: formatWholeNumber(bing.clicks),
          Impressions: formatWholeNumber(bing.impressions),
          Transactions: formatWholeNumber(bing.conversions),
          CTR: `${formatNumber(bing.ctr)}%`,
          CPC: formatCurrency2SigFig(bing.cpc),
          Revenue: formatCurrency2SigFig(bing.revenue),
          Spend: formatCurrency2SigFig(bing.cost),
          ROAS: `${formatNumber(bing.roas)}%`,
        };

        !orgConfig.priceVisible &&
          priceVisibleExclude.forEach(exclude => {
            unset(totalRowBingByDate, exclude);
          });
        data.push(totalRowBingByDate);
        break;
      case "byCampaignBing":
        const campaignsBing = cloneDeep(reportBingCampaigns);
        data = campaignsBing.map(d => {
          const cByDate = d.data.byDate;
          const filterByDate = cByDate.filter(obj => {
            if (typeof obj.date === "string") obj.date = moment(obj.date);
            return (
              obj.date >= moment(selectedStartDate) &&
              obj.date <= moment(selectedEndDate)
            );
          });

          const cData = {
            Campaign: d.name,
            "Dates Ranges": `${moment(selectedStartDate)
              .utc()
              .format("MM/DD/YYYY")} - ${moment(selectedEndDate)
              .utc()
              .format("MM/DD/YYYY")}`,
            Clicks: 0,
            Impressions: 0,
            Transactions: 0,
            CTR: 0,
            CPC: 0,
            Revenue: 0,
            Spend: 0,
            ROAS: 0,
          };
          if (filterByDate && filterByDate.length > 0) {
            let consolidatedCount = filterByDate.reduce((conso, obj) => ({
              clicks: conso.clicks + obj.clicks,
              impressions: conso.impressions + obj.impressions,
              keywords: conso.keywords + obj.keywords,
              locations: conso.locations + obj.locations,
              conversions: conso.conversions + obj.conversions,
              cost: conso.cost + obj.cost,
              revenue: conso.revenue + obj.revenue,
            }));

            const spend = consolidatedCount.cost;
            const ctr = computeCTR(
              consolidatedCount.clicks,
              consolidatedCount.impressions
            );
            const cpc = computeCPC(
              consolidatedCount.cost,
              consolidatedCount.clicks
            );
            const roas = computeROAS(consolidatedCount.revenue, spend);

            cData.Clicks = consolidatedCount.clicks;
            cData.Impressions = consolidatedCount.impressions;
            cData.Transactions = consolidatedCount.conversions;
            cData.Spend = consolidatedCount.cost;
            cData.Revenue = consolidatedCount.revenue;
            cData.CTR = ctr;
            cData.CPC = cpc;
            cData.ROAS = roas;
          }

          cData.Clicks = formatWholeNumber(cData.Clicks);
          cData.Impressions = formatWholeNumber(cData.Impressions);
          cData.Transactions = formatWholeNumber(cData.Transactions);
          cData.Spend = formatCurrency2SigFig(cData.Spend);
          cData.Revenue = formatCurrency2SigFig(cData.Revenue);
          cData.CTR = `${formatNumber(cData.CTR)}%`;
          cData.CPC = formatCurrency2SigFig(cData.CPC);
          cData.ROAS = `${formatNumber(cData.ROAS)}%`;

          priceVisibleExclude.forEach(exclude => {
            unset(cData, exclude);
          });

          return cData;
        });

        break;
      default:
        console.log(`report generation not found`);
    }
    return data;
  };

  const updateConfig = async config => {
    const {
      processWithGoogleAds,
      monthlyBudget,
      hasLocationRevenue,
      locationsKey,
      googleAdsID,
      googleAnalyticsID,
      googleAnalyticsViewIdIsGA4,
      bingAccountID,
      bingCustomerID,
      hasCommerce,
      commerceTags,
    } = config;

    let data = {
      config: {
        create: {
          monthlyBudget: monthlyBudget ? Number(monthlyBudget) : 0,
          googleCustomerId: googleAdsID,
          googleViewId: googleAnalyticsID,
          bingAccountId: bingAccountID,
          bingCustomerId: bingCustomerID,
          processWithGoogleAds: processWithGoogleAds,
          googleViewIdIsGa4: googleAnalyticsViewIdIsGA4,
        },
      },
    };

    if (hasLocationRevenue) {
      data.config.create.hasLocationRevenue = hasLocationRevenue;
      data.config.create.locationsKey = {
        set: locationsKey,
      };
    }
    if (hasCommerce) {
      data.config.create.hasCommerce = hasCommerce;
      data.config.create.commerceTags = {
        connect: {
          id: commerceTags,
        },
      };
    }

    await updateConfigMutation({
      variables: {
        id: paidSearchSummary.paidSearchSummary.id,
        data,
      },
    });

    await refetch();
  };

  const reprocessPaidSearchEvent = () => {
    reprocessPaidSearch({
      variables: {
        where: {
          dag_id: "paid-search-trigger",
          body: {
            conf: {
              function: "function=getOrg",
              orgId: `orgId=${paidSearchSummary.paidSearchSummary.org.id}`,
            },
          },
        },
      },
    });

    notification.open({
      message: "Reprocessing Paid Search...",
      description:
        "Please wait for a while. The process of new paid search are now on queue. This may take some time. ",
      icon: <FileSyncOutlined />,
      duration: 20,
    });
  };
  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        generateReport,
        updateConfig,
        setGoogleReport,
        setGoogleRevenueLocations,
        setReportBing,
        setGoogleReportCampaigns,
        setReportBingCampaigns,
        refetch,
        orgId,
        summary: paidSearchSummary.paidSearchSummary,
        loadingCampaigns,
        orgName: paidSearchSummary.paidSearchSummary.org.name || "",
        orgConfig,
        dataConfig,
        selectedSubTab,
        isMultiChannel,
        hasGoogle,
        hasBing,
        hasCommerce,
        reportCampaigns,
        reprocessPaidSearch: reprocessPaidSearchEvent,
        ...props,
      })}
    </React.Fragment>
  );
};

export default PaidSearchDetailsController;
