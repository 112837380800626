import { Button, Card, message, Table } from "antd";
import React from "react";

const TestLogsCard = ({
  logs,
  hasBasketItems,
  hasSignups,
  testTag,
  loading,
}) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
    },
  ];

  if (hasBasketItems) {
    columns.push({
      title: "Basket Items",
      dataIndex: "basketItems",
      key: "basketItems",
    });
  }

  if (hasSignups) {
    columns.push({
      title: "Sign Ups",
      dataIndex: "signups",
      key: "signups",
    });
  }

  return (
    <Card
      title="TEST LOG"
      extra={
        <Button
          shape={"round"}
          loading={loading}
          type="primary"
          onClick={async () => {
            message.info("Testing Signal");
            await testTag();
          }}
        >
          Test Signal
        </Button>
      }
    >
      <Table
        dataSource={logs}
        columns={columns}
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
};

export default TestLogsCard;
