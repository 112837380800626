import React from "react";
import { Col, Card, Row } from "antd";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../../shared/globalStyling/styledText";
import {
  formatWholeNumber,
  formatNumber,
} from "../../../../../../../core/utils/campaigns";
import { ExtraInfo } from "../../attribution/components";

const styles = {
  title: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

const DetailsBar = ({ data, aggregatedData }) => {
  const transactions = aggregatedData ? Object.keys(aggregatedData).length : 0;

  // Check if the number is valid to prevent Nan from being displayed in Sub Total and Quantity in the UI
  const checkIfValidNumber = number => {
    return typeof number === "number" && !isNaN(number) ? number : 0;
  };

  const revenue = data.reduce(
    (acc, item) =>
      acc +
      parseFloat(checkIfValidNumber(item.PRICE)) *
        parseInt(checkIfValidNumber(item.QUANTITY)),
    0
  );

  const quantity = data.reduce(
    (acc, item) => acc + parseInt(checkIfValidNumber(item.QUANTITY)),
    0
  );

  return (
    <Row>
      <Col flex={4}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0, borderRight: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text="Transactions" />
          </div>
          <KpiSubText text={formatWholeNumber(transactions)} />
        </Card>
      </Col>
      <Col flex={4}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text="Sub Total" />
            <ExtraInfo
              title="Revenue before taxes, shipping and promotions"
              placement="top"
            />
          </div>
          <KpiSubText text={`$${formatNumber(revenue)}`} />
        </Card>
      </Col>
      <Col flex={4}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text="Quantity" />
            <ExtraInfo
              title="Total number of product items within all selected transactions"
              placement="top"
            />
          </div>
          <KpiSubText text={formatWholeNumber(quantity)} />
        </Card>
      </Col>
    </Row>
  );
};

export default DetailsBar;
