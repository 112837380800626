import React, { useEffect, useState, useCallback } from "react";
import { useRetailStoresDetailsContext } from "../context/RetailStoresContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { PageHeaderLayout, PageContentLayout } from "../../../shared/layout";
import { AimOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Divider,
  Button,
  message,
  Popconfirm,
  Form,
  Input,
  Modal,
  Select,
  Checkbox,
} from "antd";
import {
  TableColumnTitle,
  StandardTableRowText,
} from "../../../shared/globalStyling/styledText";
import MapDrawing from "../../../shared/maps/MapDrawing";
import { MapConfig } from "../../../shared/maps/configs/mapConfigs";

const EditLocationForm = ({
  form,
  newLatitude,
  newLongitude,
  locationAudienceType,
}) => {
  return (
    <>
      <Form.Item
        label="Location Name"
        name="locationName"
        extra="Required name given to this location"
        rules={[
          {
            required: true,
            message: "Please input your Business name!",
          },
        ]}
      >
        <Input placeholder="Enter retail store name" />
      </Form.Item>
      <Form.Item
        label="Location Description"
        name="locationDescription"
        extra="Optional: Description for this location"
      >
        <Input placeholder="Optional description" />
      </Form.Item>
      <Row>
        <Col style={{ padding: 5 }} xs={24} xl={24}>
          <Form.Item
            label="Location Key"
            name="locationKey"
            extra="Optional: Shopping cart URL string that identifies a retail location"
          >
            <Input placeholder="Location Key" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Street Address"
        name="locationAddress"
        extra="Search and then select the address of this location"
        rules={[
          {
            required: true,
            message: "Address is required!",
          },
        ]}
      >
        <Input
          placeholder="Address"
          id="search-input-tl"
          onFocus={e => {
            e.target.setAttribute("autocomplete", "no");
          }}
        />
      </Form.Item>
      <Row>
        <Col xs={24} xl={9} style={{ padding: 5 }}>
          <Form.Item label="City" name="locationCity">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} xl={9} style={{ padding: 5 }}>
          <Form.Item label="Zip" name="locationZip">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} xl={6} style={{ padding: 5 }}>
          <Form.Item label="State" name="locationState">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="locationAudienceType" valuePropName="checked">
        <Checkbox>
          <p>
            Include this address as a Targeting Location. This location will be
            moved to the Targeting Section and can be viewed there.
          </p>
        </Checkbox>
      </Form.Item>
      <Form.Item name="formattedAddress" hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="locationCountry" hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="inProgressOverlay" hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="newLatitude" initialValue={newLatitude} hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="newLongitude" initialValue={newLongitude} hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      {/* <Row>
        <Col style={{ padding: 5 }} xs={24} xl={12}>
          <Form.Item label="Cart" name="locationCart">
            <Select style={{ width: "100%", borderRadius: 0 }}>
              {getCartProvider &&
                getCartProvider.map(val => {
                  const { name } = val;
                  return (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ padding: 5 }} xs={24} xl={12}>
          <Form.Item label="Menu Data Key" name="menuDataKey">
            <Input placeholder="Menu Data Key" />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};

const RetailStoresDetailsView = props => {
  //Title shown on browser tab
  document.title = "Retail Stores Details View";
  const history = useHistory();
  const {
    locations,
    permission,
    deleteLocation,
  } = useRetailStoresDetailsContext();
  const { google, shapes, onUpdateRetailLocation } = props;

  const [formShow, setFormShow] = useState(false);
  const [warningShow, setWarningShow] = useState(false);
  const [editBoundaries, setEditBoundaries] = useState(false);
  const [newLatitude, setNewLatitude] = useState(null);
  const [newLongitude, setNewLongitude] = useState(null);
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [pendingFormValues, setPendingFormValues] = useState({});
  const [hasOverlay, setHasOverlay] = useState(false);

  const {
    name: locationName,
    street: locationAddress,
    city: locationCity,
    state: locationState,
    zip: locationZip,
    country: locationCountry,
    formattedAddress,
    locationKey = "Not Available",
    description: locationDescription = "Not Available",
    createdAt: locationCreatedOn,
    updatedAt: locationLastUpdated,
    lat: locationLat,
    lng: locationLng,
    cart: locationCart,
    audienceType: locationAudienceType,
  } = locations.locations[0];

  // formatted times (MM/DD/YY - HH:mm [GMT])
  const formattedLocationCreatedOn = moment(locationCreatedOn)
    .utcOffset(0)
    .format("MM/DD/YY - HH:mm [GMT]");
  const formattedLocationLastUpdated = moment(locationLastUpdated)
    .utcOffset(0)
    .format("MM/DD/YY - HH:mm [GMT]");

  const [form] = Form.useForm();
  const { Option } = Select;

  const { isSelfService } = permission;

  // const columns = useMemo(
  //   () => [
  //     {
  //       title: "Campaign Name",
  //       dataIndex: "name",
  //       key: "name",
  //       render: (text, record) => (
  //         <span
  //           onClick={() => history.push(`/campaigns/details/${record.key}`)}
  //           style={{
  //             cursor: "pointer",
  //             color: "#1890FF",
  //           }}
  //         >
  //           {text}
  //         </span>
  //       ),
  //     },
  //     {
  //       title: "Flight Dates",
  //       dataIndex: "flightDates",
  //       key: "flightDates",
  //       render: (_, record) => {
  //         const startDate = moment(record.startDate).format("MM/DD/YYYY");
  //         const endDate = moment(record.endDate).format("MM/DD/YYYY");
  //         return `${startDate} - ${endDate}`;
  //       },
  //     },
  //     {
  //       title: "Budget",
  //       dataIndex: "budget",
  //       key: "budget",
  //       render: budget => `$${budget.toFixed(2)}`,
  //     },
  //   ],
  //   [history]
  // );

  // ON HOLD
  // const dataSource = useMemo(
  //   () =>
  //     assignedOrgsData.campaignOrders.map(campaign => ({
  //       key: campaign.id,
  //       name: campaign.name,
  //       startDate: campaign.startDate,
  //       endDate: campaign.endDate,
  //       budget: campaign.budget,
  //     })),
  //   [assignedOrgsData]
  // );

  // const campaignCount =
  //   assignedOrgsData && assignedOrgsData.campaignOrders
  //     ? assignedOrgsData.campaignOrders.length
  //     : 0;

  const onDeleteLocation = async () => {
    try {
      await deleteLocation();
      message.success("Location successfully deleted");
    } catch (error) {
      message.error(`Error deleting location: ${error.message}`);
    }
  };

  const onEditLocation = () => {
    setOriginalFormValues({
      locationAddress,
      locationCity,
      locationState,
      locationZip,
    });
    setFormShow(true);
  };

  const onCloseForm = () => {
    form.setFieldsValue(originalFormValues);
    setFormShow(false);
    setNewLatitude(undefined);
    setNewLongitude(undefined);
  };

  const onShowWarning = () => {
    form.setFieldsValue(originalFormValues);
    setFormShow(false);
  };

  const onCloseWarning = () => {
    setWarningShow(false);
    setFormShow(true);
  };

  const onCloseMap = () => {
    setEditBoundaries(false);
    setNewLatitude(undefined);
    setNewLongitude(undefined);
  };

  const onEditBoundaries = () => {
    if (!isValidCoordinate(locationLat) || !isValidCoordinate(locationLng)) {
      message.error("Invalid location coordinates. Please try again.");
      return;
    }

    const currentFormValues = form.getFieldsValue();
    setPendingFormValues(prevValues => ({
      ...prevValues,
      ...currentFormValues,
      lat: newLatitude || locationLat,
      lng: newLongitude || locationLng,
    }));
    setWarningShow(false);
    setEditBoundaries(true);
  };

  const onUpdateLocation = () => {
    const formValues = form.getFieldsValue();
    const updatedValues = {
      ...pendingFormValues,
      ...formValues,
      lat: newLatitude || locationLat,
      lng: newLongitude || locationLng,
      inProgressOverlay: form.getFieldValue("inProgressOverlay"),
    };
    setEditBoundaries(false);
    onUpdateRetailLocation(updatedValues);
  };

  const hasAddressChanged = (originalValues, formValues) => {
    return (
      originalValues.locationAddress !== formValues.locationAddress ||
      originalValues.locationCity !== formValues.locationCity ||
      originalValues.locationZip !== formValues.locationZip ||
      originalValues.locationState !== formValues.locationState
    );
  };

  const searchInputPlacesInit = useCallback(() => {
    const input = document.getElementById("search-input-tl");
    if (input) {
      input.addEventListener("keydown", e => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      });

      var options = {
        componentRestrictions: MapConfig.componentRestrictions,
      };

      const searchBox = new google.maps.places.Autocomplete(input, options);

      searchBox.addListener("place_changed", () => {
        const place = searchBox.getPlace();

        if (!place || !place.geometry || !place.geometry.location) {
          message.error("Error: Place not found!");
          return;
        }

        updateFormFields(place);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormFields = place => {
    const { geometry } = place;
    let address = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address = `${component.long_name} ${address}`;
          break;
        case "route":
          address += component.short_name;
          break;
        case "postal_code":
          zip = `${component.long_name}${zip}`;
          break;
        case "postal_code_suffix":
          zip = `${zip}-${component.long_name}`;
          break;
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.long_name;
          break;
        case "country":
          country = component.short_name;
          break;
        default:
          console.warn(`Unknown component type: ${componentType}`);
          break;
      }
    }

    const formattedState = state.replace(/\s+/g, "_").toUpperCase();
    const formattedCountry = country === "US" ? "USA" : country;

    form.setFieldsValue({
      formattedAddress: place.formatted_address,
      locationAddress: address,
      locationCity: city,
      locationState: formattedState,
      locationZip: zip,
      locationCountry: formattedCountry,
    });

    if (geometry && geometry.viewport) {
      const { Wh, Gh } = geometry.viewport;

      const calculatedLat = Number(((Wh.hi + Wh.lo) / 2).toFixed(7));
      const calculatedLng = Number(((Gh.hi + Gh.lo) / 2).toFixed(7));

      setNewLatitude(calculatedLat);
      setNewLongitude(calculatedLng);
    }
  };

  useEffect(() => {
    searchInputPlacesInit();
  });

  // Used in Edit Location Boundaries
  const initializeFormValues = useCallback(() => {
    return {
      locationName,
      locationDescription,
      locationAddress,
      locationCity,
      locationState,
      locationZip,
      locationCart: locationCart ? locationCart : "NOCART",
      locationKey,
      formattedAddress,
      locationCountry,
      inProgressOverlay: "",
      locationAudienceType: locationAudienceType === "TARGETING",
      lat: locationLat,
      lng: locationLng,
    };
  }, [
    locationName,
    locationDescription,
    locationAddress,
    locationCity,
    locationState,
    locationZip,
    locationCart,
    locationKey,
    formattedAddress,
    locationCountry,
    locationAudienceType,
    locationLat,
    locationLng,
  ]);

  useEffect(() => {
    setPendingFormValues(initializeFormValues());
    searchInputPlacesInit();
  }, [initializeFormValues, searchInputPlacesInit, setPendingFormValues]);

  useEffect(() => {
    setPendingFormValues(initializeFormValues());
    searchInputPlacesInit();
  }, [initializeFormValues, searchInputPlacesInit, setPendingFormValues]);

  const isValidCoordinate = value => {
    return typeof value === "number" && isFinite(value);
  };

  const validLocationLat = isValidCoordinate(locationLat) ? locationLat : null;
  const validLocationLng = isValidCoordinate(locationLng) ? locationLng : null;

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<AimOutlined />}
        title={locationName}
        rightContent={
          <Button onClick={() => history.push("/retails_stores/main/1")}>
            Back
          </Button>
        }
      />
      <PageContentLayout>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={16}>
              <div style={{ marginBottom: "16px" }}>
                <Card
                  title="LOCATION DETAILS"
                  extra={
                    <>
                      {isSelfService && (
                        <Popconfirm
                          title="Are you sure you want to delete this location?"
                          onConfirm={onDeleteLocation}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button style={{ marginRight: "8px" }}>
                            Delete Location
                          </Button>
                        </Popconfirm>
                      )}
                      <Button onClick={() => onEditLocation()}>
                        Edit Location Details
                      </Button>
                    </>
                  }
                >
                  <Row gutter={[0, 16]}>
                    <Col span={12}>
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <TableColumnTitle text={"Street Address"} />
                          <StandardTableRowText text={locationAddress} />
                        </Col>
                        <Col span={8}>
                          <TableColumnTitle text={"City"} />
                          <StandardTableRowText text={locationCity} />
                        </Col>
                        <Col span={8}>
                          <TableColumnTitle text={"State"} />
                          <StandardTableRowText text={locationState} />
                        </Col>
                        <Col span={8}>
                          <TableColumnTitle text={"Zip Code"} />
                          <StandardTableRowText text={locationZip} />
                        </Col>
                        <Col span={24}>
                          <TableColumnTitle text={"Location Key"} />
                          <StandardTableRowText text={locationKey} />
                        </Col>
                        <Col span={24}>
                          <TableColumnTitle text={"Description"} />
                          <StandardTableRowText text={locationDescription} />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                      <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col span={11}>
                      <Row gutter={[0, 24]}>
                        {/* TEMP: Hide Assigned Campaigns */}
                        {/* <Col span={24}>
                          <TableColumnTitle text={"Assigned Campaigns"} />
                          <StandardTableRowText text={"0"} />
                        </Col> */}
                        <Col span={24}>
                          <TableColumnTitle text={"Last Updated"} />
                          <StandardTableRowText
                            text={formattedLocationLastUpdated}
                          />
                        </Col>
                        <Col span={24}>
                          <TableColumnTitle text={"Created On"} />
                          <StandardTableRowText
                            text={formattedLocationCreatedOn}
                          />
                        </Col>
                        <Col span={24}>
                          <TableColumnTitle text={"Targeting Location?"} />
                          <StandardTableRowText
                            text={
                              locationAudienceType === "TARGETING"
                                ? "Yes"
                                : "No"
                            }
                          />
                        </Col>
                        {/* <Col span={24}>
                          <TableColumnTitle text={"Advertiser"} />
                          <StandardTableRowText
                            text={"Finding Haven - to edit"}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              {/* ON HOLD */}
              {/* <Card
                title={"0 Assigned Campaigns"}
                extra={
                  <Button
                    // type="primary"
                    onClick={() => history.push("/retails_stores/main/1")}
                  >
                    Assign to Campaigns
                  </Button>
                }
              >
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <Table
                      style={{ width: "100%" }}
                      columns={columns}
                      // dataSource={dataSource}
                      size={"default"}
                      scroll={{ x: 300 }}
                    />
                  </Col>
                </Row>
              </Card> */}
            </Col>
            <Col span={8}>
              <Card
                title="BOUNDARIES"
                extra={
                  <Button onClick={() => onEditBoundaries()}>
                    Edit Boundaries
                  </Button>
                }
              >
                <MapDrawing
                  lat={locationLat}
                  lng={locationLng}
                  zoom={17}
                  shapes={shapes}
                  initialLocation={{
                    latitude: locationLat,
                    longitude: locationLng,
                  }}
                  currentLocation={{
                    latitude: locationLat,
                    longitude: locationLng,
                  }}
                  isSearchBarVisible={false}
                  isClearButtonVisible={false}
                  enableDrawingManager={false}
                  mapType={"satellite"}
                />
              </Card>
            </Col>
          </Row>
          {formShow && (
            <Modal
              visible={formShow}
              title="EDIT LOCATION"
              onOk={form.submit}
              onCancel={onCloseForm}
              footer={null}
            >
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                  locationName,
                  locationDescription,
                  locationAddress,
                  locationCity,
                  locationState,
                  locationZip,
                  locationCart: locationCart ? locationCart : "NOCART",
                  locationKey,
                  formattedAddress,
                  locationCountry,
                  inProgressOverlay: "",
                  locationAudienceType: locationAudienceType === "TARGETING",
                }}
                onFinish={values => {
                  const originalValues = {
                    locationAddress,
                    locationCity,
                    locationZip,
                    locationState,
                  };

                  const transformedValues = {
                    ...values,
                    locationAudienceType: values.locationAudienceType
                      ? "TARGETING"
                      : "ATTRIBUTION",
                  };

                  setPendingFormValues(transformedValues);

                  if (!hasAddressChanged(originalValues, transformedValues)) {
                    // Address hasn't changed, directly update the location
                    onUpdateRetailLocation(transformedValues);
                    onCloseForm();
                  } else {
                    // Address has changed, show warning modal
                    onShowWarning();
                    setWarningShow(true);
                  }
                }}
              >
                <EditLocationForm
                  form={form}
                  Option={Option}
                  locationAudienceType={locationAudienceType}
                  newLatitude={newLatitude}
                  newLongitude={newLongitude}
                />
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Button onClick={onCloseForm}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          )}
          {warningShow && (
            <Modal
              visible={warningShow}
              title="WARNING"
              okText="Proceed"
              cancelText="Cancel"
              onCancel={() => {
                setWarningShow(false);
              }}
              footer={[
                <Button key="cancel" onClick={() => onCloseWarning()}>
                  Cancel
                </Button>,
                <Button
                  key="proceed"
                  type="primary"
                  onClick={() => {
                    setWarningShow(false);
                    setEditBoundaries(true);
                  }}
                >
                  Proceed
                </Button>,
              ]}
            >
              <p>
                Editing the address of this location will remove all currently
                defined boundary areas.
              </p>
            </Modal>
          )}
          {editBoundaries && (
            <Modal
              visible={editBoundaries}
              title="EDIT LOCATION BOUNDARY AREA"
              onCancel={() => {
                setEditBoundaries(false);
                setNewLatitude(undefined);
                setNewLongitude(undefined);
                setHasOverlay(false);
              }}
              bodyStyle={{ padding: 0 }}
              footer={[
                <Button key="cancel" onClick={() => onCloseMap()}>
                  Cancel
                </Button>,
                <Button
                  key="proceed"
                  type="primary"
                  onClick={() => onUpdateLocation()}
                  disabled={!hasOverlay}
                >
                  Update
                </Button>,
              ]}
            >
              {isValidCoordinate(locationLat) &&
              isValidCoordinate(locationLng) ? (
                <MapDrawing
                  lat={
                    newLatitude === undefined ? validLocationLat : newLatitude
                  }
                  lng={
                    newLongitude === undefined ? validLocationLng : newLongitude
                  }
                  zoom={17}
                  shapes={[]}
                  initialLocation={{
                    latitude: newLatitude || locationLat,
                    longitude: newLongitude || locationLng,
                  }}
                  currentLocation={{
                    latitude: newLatitude || locationLat,
                    longitude: newLongitude || locationLng,
                  }}
                  overlayChanged={val => {
                    const overlayValue = Array.isArray(val) ? val : [val];
                    form.setFieldsValue({ inProgressOverlay: overlayValue });
                    setHasOverlay(overlayValue.length > 0);
                  }}
                  isSearchBarVisible={false}
                  mapType={"satellite"}
                />
              ) : (
                <div>Loading map coordinates...</div>
              )}
            </Modal>
          )}
        </Col>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default RetailStoresDetailsView;
