import gql from "graphql-tag";

const CREATE_EDITORIAL = gql`
  mutation createEditorial($data: EditorialCreateInput!) {
    createEditorial(data: $data) {
      id
      name
    }
  }
`;

export default CREATE_EDITORIAL;
