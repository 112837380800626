import gql from "graphql-tag";

const EDITORIAL_CONNECTION = gql`
  query EditorialConnection(
    $where: EditorialWhereInput
    $orderBy: EditorialOrderByInput
  ) {
    editorialsConnection(where: $where, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          startDate
          endDate
          name
          transactions
          revenue
          customers
          clicks
          domain
          url
          trackMesurements
          sharedOrgs {
            id
            isAdmin
            isOwner
            isClickEvents
            isTransactionsEvents
            org {
              id
              name
            }
          }
          clicksTag {
            id
            orgs(first: 1) {
              id
              name
            }
          }
          transactionsTag {
            id
            orgs(first: 1) {
              id
              name
            }
          }
          transactionsTags {
            id
            createdAt
            updatedAt
            trackMesurements
            editorial {
              id
              name
            }
            eventsTarget {
              id
              name
            }
          }
        }
      }
      aggregate {
        count
      }
    }
    full: editorialsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default EDITORIAL_CONNECTION;
