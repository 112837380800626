export const adminOrganizations = ["MediaJel", "MediaJelAdmin"];
export const mediajelAdminOrgIdStaging = "cjlwwzv86hn3q0726mqm60q3f";
export const mediajelAdminOrgIdProduction = "cjpisc9ffncwv077405zwkzjo";

export const mediajelOrgIdStaging = "cjoq2t7g4yzca07347pug25ck";
export const mediajelOrgIdProduction = "cjpkhilv4es4q0722xgsd8k12";
export const defaultAdvertiserName = "MediaJel";
export const defaultAdvertiserWebsite = "www.mediajel.com";

export const chartspallete = {
  blue: "#1665d8",
  green: "#34aa44",
  grey: "#bec0c3",
  black: "#99999",
};

export const MAX_AREA = 78988900;

export const colors = [
  {
    color: "blue",
  },
  {
    color: "green",
  },
  {
    color: "purple",
  },
  {
    color: "red",
  },
  {
    color: "orange",
  },
  {
    color: "grey",
  },
  {
    color: "yellow",
  },
  {
    color: "magenta",
  },
  {
    color: "purple",
  },
  {
    color: "red",
  },
  {
    color: "orange",
  },
];

export const orgLevelMenuItems = [
  { name: "STANDARD", value: "STANDARD" },
  { name: "CHANNEL PARTNER", value: "CHANNEL_PARTNER" },
];

export const interests = [
  "Flower",
  "Pre Rolls",
  "Vape Pens",
  "Vape Cartridges",
  "Edibles",
  "Dabs",
  "Topicals",
  "Capsules",
  "Concentrates",
  "Tinctures",
  "Accessories",
];

export const colorTheme = {
  border: "#e8e8e8",
};

export const subRoutes = {
  CAMPAIGNS: ["Campaigns", "Overview", "Reports"],
  PAID_SEARCH: ["Paid Search Campaign", "Paid Search Reports"],
  SEO: ["SEO Domains", "SEO Reports"],
  LOYALTY: ["New Offer"],
  SEGMENTS: [
    "Old Geo Targets",
    "New Geo Targets",
    // "Events Targets"
  ],
};

export const errorMessages = {
  ERR_LIQUID_M: "Failed to Launched Campaign to Liquid M",
};

export const subDivisions = {
  MAIN: [
    "CAMPAIGNS",
    "PAID_SEARCH",
    "SEO",
    "AUDIENCES",
    "SEGMENTS",
    "LIBRARY",
    "LOCATIONS",
    "USERS",
    "AI",
    "HOME",
    "DEMOGRAPHIC",
  ],
  "ATTENTION SIGNALS": ["ADVERTISER", "PUBLISHER", "SIGNAL TAGS"], //TEMPORARILY REMOVED "INVOICES" AND "ADVERTISER"
  LIBRARY: ["AUDIENCE", "TARGETING", "TAGS", "CREATIVES", "RETAILS_STORES"],
  ADMIN: ["KNOWLEDGE_BASE", "SETTINGS", "ORGS"],
};

export const categories = [
  "Airlines",
  "Apparel",
  "Appliances",
  "Automotive and Vehicles",
  "Computers and Electronics",
  "Cosmetics and Hygiene",
  "Education",
  "Employment",
  "Financial",
  "Food and Drink",
  "Health-care",
  "Hotels",
  "Home and Decor",
  "Insurance",
  "Jewelry",
  "Movies",
  "Music",
  "Office Supplies",
  "Print and Publications",
  "Retail",
  "Premium Services",
  "Sporting Goods",
  "Telecommunications",
  "Television",
  "Toys/Games",
  "Travel Services",
  "Travel Destinations",
  "Pets",
  "Software and Web Apps",
  "Non Profit",
  "Misc. Other",
  "Video Games",
  "Cosmetic Procedures",
  "Sports and Events",
  "Online Advertising Companies",
  "Tier 2 Services",
  "Real Estate",
  "Family and Parenting",
  "Weather",
  "Holidays",
  "Legal",
  "Fitness and Health",
  "Deals and Coupons",
  "News",
  "Flowers and Gifts",
  "Government",
  "Arrest Records",
  "GDN Remarketing",
  "Financial: Forex",
  "No Default Offer Category",
];

export const languages = [
  "Arabic",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swedish",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
];

export const ages = [
  "18 - 24",
  "25 - 34",
  "35 - 44",
  "45 - 54",
  "55 - 64",
  "65 - 100",
  "Any",
];

export const incomes = [
  "$0 - $30k",
  "$30k - $60k",
  "$60k - $80k",
  "$80k - $100k",
  "$100 - $125k",
  "$125k - $150k",
  "$150k+",
];

export const possibleFields = {
  Name: {
    title: "Name",
    key: "name",
  },
  Status: {
    title: "Status",
    key: "status",
  },
  DaysRemaining: {
    title: "Days Remaining",
    key: "daysRemaining",
    configKey: "isShowDaysRemaining",
  },
  StartDate: {
    title: "Start Date",
    key: "startDate",
    configKey: "isShowStartDate",
  },
  EndDate: {
    title: "End Date",
    key: "endDate",
    configKey: "isShowEndDate",
  },
  Impressions: {
    title: "Impressions",
    key: "impressionsAggregate",
    dataAccessor: "aggImpressions",
    configKey: "isShowImpressions",
  },

  Walkins: {
    title: "Walk ins",
    key: "aggVWI",
    configKey: "isShowWalkins",
  },

  Performance: {
    title: "Performance",
    key: "performanceStatus",
    configKey: "isShowPerformance",
  },

  Pacing: {
    title: "Pacing",
    key: "pacing",
    isPriceRelated: true,
    configKey: "isShowPacing",
  },

  Sales: {
    title: "Sales",
    key: "sales",
    configKey: "isShowSales",
  },

  SignUps: {
    title: "Sign Ups",
    key: "signUps",
    configKey: "isShowSignUps",
  },

  //---------------------------------------
  /*
  Spent: {
    title: "Spent",
    key: "spent",
    isPriceRelated: true,
  },
 */
  //---------------------------------------
  Budget: {
    title: "Budget",
    key: "budgetTotal",
    isPriceRelated: true,
    configKey: "isShowBudget",
  },
  Attribution: {
    title: "Attribution",
    key: "attributionStatus",
    configKey: "isShowAttribution",
  },
};

export const possibleFieldsPaidSearch = {
  Name: {
    title: "Name",
    key: "org.name",
  },
  Flight: {
    title: "Flight",
    key: "startDate",
  },
  Impressions: {
    title: "Impressions",
    key: "impressionsAggregate",
    dataAccessor: "impressions",
    configKey: "isShowImpressions",
  },
  Performance: {
    title: "Performance",
    key: "performanceStatus",
    configKey: "isShowPerformance",
  },
  Transactions: {
    title: "Transactions",
    key: "transacitons",
  },
  Budget: {
    title: "Budget",
    key: "budgetTotal",
    isPriceRelated: true,
    configKey: "isShowBudget",
  },
  Channels: {
    title: "Channels",
    key: "channels",
  },
};

export const possibleFieldsPaidSearchCampaign = {
  Name: {
    title: "Name",
    key: "name",
  },
  Flight: {
    title: "Flight",
    key: "paidSearchSummary.startDate",
  },
  Performance: {
    title: "Performance",
    key: "performanceStatus",
    configKey: "isShowPerformance",
  },
  Commerce: {
    title: "Commerce",
    key: "paidSearchSummary",
  },
};

export const maxRadius = 1730;

export const minRadius = 15;

export const defaultRadius = 800;

export const stateMap = {
  "United States": "USA",
  Canada: "CANADA",
  Alabama: "ALABAMA",
  Alaska: "ALASKA",
  "American Samoa": "AMERICAN_SAMOA",
  Arizona: "ARIZONA",
  Arkansas: "ARKANSAS",
  California: "CALIFORNIA",
  Colorado: "COLORADO",
  Connecticut: "CONNECTICUT",
  Delaware: "DELAWARE",
  "District of Columbia": "DISTRICT_OF_COLUMBIA",
  "Federated States of Micronesia": "FEDERATED_STATES_OF_MICRONESIA",
  Florida: "FLORIDA",
  Georgia: "GEORGIA",
  Guam: "GUAM",
  Hawaii: "HAWAII",
  Idaho: "IDAHO",
  Illinois: "ILLINOIS",
  Indiana: "INDIANA",
  Iowa: "IOWA",
  Kansas: "KANSAS",
  Kentucky: "KENTUCKY",
  Louisiana: "LOUISIANA",
  Maine: "MAINE",
  "Marshall Islands": "MARSHALL_ISLANDS",
  Maryland: "MARYLAND",
  Massachusetts: "MASSACHUSETTS",
  Michigan: "MICHIGAN",
  Minnesota: "MINNESOTA",
  Mississippi: "MISSISSIPPI",
  Missouri: "MISSOURI",
  Montana: "MONTANA",
  Nebraska: "NEBRASKA",
  Nevada: "NEVADA",
  "New Hampshire": "NEW_HAMPSHIRE",
  "New Jersey": "NEW_JERSEY",
  "New Mexico": "NEW_MEXICO",
  "New York": "NEW_YORK",
  "North Carolina": "NORTH_CAROLINA",
  "North Dakota": "NORTH_DAKOTA",
  "Northern Mariana Islands": "NORTHERN_MARIANA_ISLANDS",
  Ohio: "OHIO",
  Oklahoma: "OKLAHOMA",
  Oregon: "OREGON",
  Palau: "PALAU",
  Pennsylvania: "PENNSYLVANIA",
  "Puerto Rico": "PUERTO_RICO",
  "Rhode Island": "RHODE_ISLAND",
  "South Carolina": "SOUTH_CAROLINA",
  "South Dakota": "SOUTH_DAKOTA",
  Tennessee: "TENNESSEE",
  Texas: "TEXAS",
  Utah: "UTAH",
  Vermont: "VERMONT",
  "U.S. Virgin Islands": "VIRGIN_ISLAND",
  Virginia: "VIRGINIA",
  Washington: "WASHINGTON",
  "West Virginia": "WEST_VIRGINIA",
  Wisconsin: "WISCONSIN",
  Wyoming: "WYOMING",
  Alberta: "ALBERTA",
  "British Columbia": "BRITISH_COLUMBIA",
  Manitoba: "MANITOBA",
  "New Brunswick": "NEW_BRUNSWICK",
  "Newfoundland And Labrador": "NEWFOUNDLAND_AND_LABRADOR",
  "Nova Scotia": "NOVA_SCOTIA",
  Ontario: "ONTARIO",
  "Prince Edward Island": "PRINCE_EDWARD_ISLAND",
  Quebec: "QUEBEC",
  Saskatchewan: "SASKATCHEWAN",
};

export const stateList = [
  "ALABAMA",
  "ALASKA",
  "AMERICAN_SAMOA",
  "ARIZONA",
  "ARKANSAS",
  "CALIFORNIA",
  "COLORADO",
  "CONNECTICUT",
  "DELAWARE",
  "DISTRICT_OF_COLUMBIA",
  "FEDERATED_STATES_OF_MICRONESIA",
  "FLORIDA",
  "GEORGIA",
  "GUAM",
  "HAWAII",
  "IDAHO",
  "ILLINOIS",
  "INDIANA",
  "IOWA",
  "KANSAS",
  "KENTUCKY",
  "LOUISIANA",
  "MAINE",
  "MARSHALL_ISLANDS",
  "MARYLAND",
  "MASSACHUSETTS",
  "MICHIGAN",
  "MINNESOTA",
  "MISSISSIPPI",
  "MISSOURI",
  "MONTANA",
  "NEBRASKA",
  "NEVADA",
  "NEW_HAMPSHIRE",
  "NEW_JERSEY",
  "NEW_MEXICO",
  "NEW_YORK",
  "NORTH_CAROLINA",
  "NORTH_DAKOTA",
  "NORTHERN_MARIANA_ISLANDS",
  "OHIO",
  "OKLAHOMA",
  "OREGON",
  "PALAU",
  "PENNSYLVANIA",
  "PUERTO_RICO",
  "RHODE_ISLAND",
  "SOUTH_CAROLINA",
  "SOUTH_DAKOTA",
  "TENNESSEE",
  "TEXAS",
  "UTAH",
  "VERMONT",
  "VIRGIN_ISLAND",
  "VIRGINIA",
  "WASHINGTON",
  "WEST_VIRGINIA",
  "WISCONSIN",
  "WYOMING",
  "ALBERTA",
  "BRITISH_COLUMBIA",
  "MANITOBA",
  "NEW_BRUNSWICK",
  "NEWFOUNDLAND_AND_LABRADOR",
  "NOVA_SCOTIA",
  "ONTARIO",
  "PRINCE_EDWARD_ISLAND",
  "QUEBEC",
  "SASKATCHEWAN",
  "UNKNOWN",
];

export const campaignTypeMap = {
  1: "DISPLAY",
  2: "OOH",
  3: "DOOH",
  4: "SOCIAL",
  5: "LOYALTY",
  6: "CPC",
  7: "EXTERNAL",
  8: "VIDEO",
  DISPLAY: 1,
  OOH: 2,
  DOOH: 3,
  SOCIAL: 4,
  LOYALTY: 5,
  CPC: 6,
  EXTERNAL: 7,
  VIDEO: 8,
};

export const orgFeaturesInfo = {
  CAMPAIGNS:
    "Ability to view all campaigns belonging to your organization and sub organizations. Campaign details, Display, Transactions and Walk Ins.",
  AUDIENCES:
    "Ability to view audiences belonging to your organization and sub organizations. Retargeting, Attribution and Targeting",
  SEGMENTS:
    "Ability to create and manage both Event and Geo based segments. Segments are the building blocks needed for audience creation",
  ORGS: "Ability to administer Organization and Users.",
  HORIZONTAL_NAVIGATION:
    "Ability to set navigation from vertical (default) to horizontal",
};

export const orgConfigInfo = {
  providersVisible:
    "Can see actual DSP providers where media has been ran - default will be Tier01 - Tier01 - Tier03 etc.",
  isDemo:
    "Sales tool - Allows for platform demonstrations that shows generic data not associated with a specific client.",
  isAdmin: "The ability to create Organizations and Users.",
  isPartner:
    "Can see actual price data - the true cost charged by the DSPs for Media.",
  isTrafficker:
    "Ability to Edit campaigns, create audiences and use campaign trafficking tools. (Tag Generator and Refresh observations)",
  exportData:
    "The ability to export campaign and campaign summary data as .csv files ",
  isSelfService: "Ability to request campaigns and pay bills online. (Beta)",
  hasInsights:
    "Ability to see the applications and websites where impressions are being served for display campaigns.",
  priceVisible:
    "Turns on the Budget and Pacing columns in the main campaign's view.",
  isProcessor:
    "The ability to process incoming campaign requests using the campaign order processing queue",
  canResetPasswords:
    "Ability to be able to reset passwords for all organization users.",
  campaignSummary: "Ability to view the Summary in Campaign",
  isPacing: "Pacing Report",
  pastData: "Ability to Look back Past Data",
  segment: "Ability to use Segments",
  technicalSupport: "Can see timeline",
  amplify:
    "Amplify is a cost-effective, always-on campaign that is similar to our main campaign product but easier to implement with limited options. Users simply need to turn on Amplify, assign at least one set of targeting criteria and one set of creatives. It does not require budget settings or dates. Amplify operates on a monthly subscription basis, where you pay a flat fee each month.",
  configurator:
    "Configurator is an administrator-level permission designed to perform product-specific configurations when required. It is used to configure provider-specific settings necessary for interacting with third-party APIs. This includes, but is not limited to, Google customer and view IDs, Google Search Console configurations, Bing/Microsoft integrations, and other related configurations for partner API integrations. When enabled, additional configuration options will appear within an organization's 'Settings' page.",
  billing:
    "This permission allows customers to view and pay their invoices from within the platform. It requires the advertiser to have an active billing account set up via Square. Please note that ACH billing is not supported at this time. If you are unsure about your billing method, please contact your Technical Account Manager.",
  sitesAppsTransactions:
    "This feature provides additional visualizations that show which websites and mobile applications are driving the most or least sales. These visualizations appear on the Transactions tab within our Display Campaigns product for each individual campaign. These metrics can be used for campaign optimization to determine which apps should be blocked and which ones should receive higher bids.",
};

export const daysWeek = {
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
};

export const orderDays = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const configType = {
  DATACONFIG: "DATACONFIG",
  CONFIG: "CONFIG",
  PERMISSION: "PERMISSION",
};

export const permission = {
  READ: "READ",
  WRITE: "WRITE",
};

export const errorType = {
  // Org Creation
  createOrg: "createOrg",
  nameDuplicate:
    "GraphQL error: A unique constraint would be violated on Org. Details: Field name = name",
  domainDuplicate:
    "GraphQL error: A unique constraint would be violated on Org. Details: Field name = domain",
  // Campaign Order
  createCampaignOrder: "createCampaignOrder",
  updateCampaignOrder: "updateCampaignOrder",
  campaignOrderDuplicate:
    "GraphQL error: A unique constraint would be violated on CampaignOrder. Details: Field name = name",
  launchObservationJob: "Campaign Order has no associated Geo Targets",
  // User Creation
  createDashboardUser: "createDashboardUser",
  usernameDuplication: "GraphQL error: Username already exists",
  //Media
  deleteMedia:
    "GraphQL error: The change you are trying to make would violate the required relation 'CreativeToMedia' between Creative and Media",

  getCustomer: "getCustomer",
  getDashboardUser: "getDashboardUser",
  deleteLocation: "deleteLocation",

  updateParentOrg: "updateParentOrg",

  campaignOrder: "campaignOrder",
  createCampaign: "createCampaign",
  launchCampaignDSP: "launchCampaignDSP",
  startLiquidMCampaign: "startLiquidMCampaign",
};

export const orgConfigNaming = {
  providersVisible: "Providers Visible",
  isDemo: "Demo",
  isAdmin: "Advertiser Management",
  isPartner: "Partner",
  isTrafficker: "Trafficker",
  exportData: "Export Data",
  isSelfService: "Self Service",
  hasInsights: "Domains and App",
  priceVisible: "Price Visible",
  isProcessor: "Processor",
  canResetPasswords: "Password Reset",
  campaignSummary: "Campaign Summary",
  isPacing: "Pacing",
  pastData: "Past Data",
  segment: "Segment",
  technicalSupport: "Technical Support",
  articles: "Articles",
  amplify: "Amplify",
  configurator: "Configurator",
  billing: "Billing",
  sitesAppsTransactions: "Sites and Apps Transactions",
};

export const dataConfigNaming = {
  reTargeting: "ReTargeting",
  appIds: "App IDs",
  tradeDeskIdentifier: "Trade Desk Identifier",
  storageBucket: "Storage Bucket",
  googleCustomerId: "Google Customer Id",
  seo: "SEO",
  semrushEnable: "Semrush",
  semrushConfig: "Semrush Config",
  googleAnalytics: "ViewId",
  bing: "Bing",
  tagProcessingConfig: "Tag Processing Config",
  paidSearchDataConfig: "Paid Search Config",
};

export const campaignsText = {
  exCreative: "Example Creative 320 X 480",
  exPublisher: "Example Publisher",
  exLocation: "Example Location",
  exCampaign: "Example Campaign",
  exAudiences: "Example Audiences",
};

export const iabCategories = [
  { category: "Books & Literature", code: "IAB1-1" },
  { category: "Celebrity Fan/Gossip", code: "IAB1-2" },
  { category: "Fine Art", code: "IAB1-3" },
  { category: "Humor", code: "IAB1-4" },
  { category: "Movies", code: "IAB1-5" },
  { category: "Music", code: "IAB1-6" },
  { category: "Television", code: "IAB1-7" },
  { category: "Auto Parts", code: "IAB2-1" },
  { category: "Auto Repair", code: "IAB2-2" },
  { category: "Buying/Selling Cars", code: "IAB2-3" },
  { category: "Car Culture", code: "IAB2-4" },
  { category: "Certified Pre-Owned", code: "IAB2-5" },
  { category: "Convertible", code: "IAB2-6" },
  { category: "Coupe", code: "IAB2-7" },
  { category: "Crossover", code: "IAB2-8" },
  { category: "Diesel", code: "IAB2-9" },
  { category: "Electric Vehicle", code: "IAB2-10" },
  { category: "Hatchback", code: "IAB2-11" },
  { category: "Hybrid", code: "IAB2-12" },
  { category: "Luxury", code: "IAB2-13" },
  { category: "MiniVan", code: "IAB2-14" },
  { category: "Motorcycles", code: "IAB2-15" },
  { category: "Off-Road Vehicles", code: "IAB2-16" },
  { category: "Performance Vehicles", code: "IAB2-17" },
  { category: "Pickup", code: "IAB2-18" },
  { category: "Road-Side Assistance", code: "IAB2-19" },
  { category: "Sedan", code: "IAB2-20" },
  { category: "Trucks & Accessories", code: "IAB2-21" },
  { category: "Vintage Cars", code: "IAB2-22" },
  { category: "Wagon", code: "IAB2-23" },
  { category: "Advertising", code: "IAB3-1" },
  { category: "Agriculture", code: "IAB3-2" },
  { category: "Biotech/Biomedical", code: "IAB3-3" },
  { category: "Business Software", code: "IAB3-4" },
  { category: "Construction", code: "IAB3-5" },
  { category: "Forestry", code: "IAB3-6" },
  { category: "Government", code: "IAB3-7" },
  { category: "Green Solutions", code: "IAB3-8" },
  { category: "Human Resources", code: "IAB3-9" },
  { category: "Logistics", code: "IAB3-10" },
  { category: "Marketing", code: "IAB3-11" },
  { category: "Metals", code: "IAB3-12" },
  { category: "Career Planning", code: "IAB4-1" },
  { category: "College", code: "IAB4-2" },
  { category: "Financial Aid", code: "IAB4-3" },
  { category: "Job Fairs", code: "IAB4-4" },
  { category: "Job Search", code: "IAB4-5" },
  { category: "Resume Writing/Advice", code: "IAB4-6" },
  { category: "Nursing", code: "IAB4-7" },
  { category: "Scholarships", code: "IAB4-8" },
  { category: "Telecommuting", code: "IAB4-9" },
  { category: "U.S. Military", code: "IAB4-10" },
  { category: "Career Advice", code: "IAB4-11" },
  { category: "7-12 Education", code: "IAB5-1" },
  { category: "Adult Education", code: "IAB5-2" },
  { category: "Art History", code: "IAB5-3" },
  { category: "College Administration", code: "IAB5-4" },
  { category: "College Life", code: "IAB5-5" },
  { category: "Distance Learning", code: "IAB5-6" },
  { category: "English as a 2nd Language", code: "IAB5-7" },
  { category: "Language Learning", code: "IAB5-8" },
  { category: "Graduate School", code: "IAB5-9" },
  { category: "Homeschooling", code: "IAB5-10" },
  { category: "Homework/Study Tips", code: "IAB5-11" },
  { category: "K-6 Educators", code: "IAB5-12" },
  { category: "Private School", code: "IAB5-13" },
  { category: "Special Education", code: "IAB5-14" },
  { category: "Studying Business", code: "IAB5-15" },
  { category: "Adoption", code: "IAB6-1" },
  { category: "Babies & Toddlers", code: "IAB6-2" },
  { category: "Daycare/Pre School", code: "IAB6-3" },
  { category: "Family Internet", code: "IAB6-4" },
  { category: "Parenting - K-6 Kids", code: "IAB6-5" },
  { category: "Parenting teens", code: "IAB6-6" },
  { category: "Pregnancy", code: "IAB6-7" },
  { category: "Special Needs Kids", code: "IAB6-8" },
  { category: "Eldercare", code: "IAB6-9" },
  { category: "Exercise", code: "IAB7-1" },
  { category: "A.D.D.", code: "IAB7-2" },
  { category: "AIDS/HIV", code: "IAB7-3" },
  { category: "Allergies", code: "IAB7-4" },
  { category: "Alternative Medicine", code: "IAB7-5" },
  { category: "Arthritis", code: "IAB7-6" },
  { category: "Asthma", code: "IAB7-7" },
  { category: "Autism/PDD", code: "IAB7-8" },
  { category: "Bipolar Disorder", code: "IAB7-9" },
  { category: "Brain Tumor", code: "IAB7-10" },
  { category: "Cancer", code: "IAB7-11" },
  { category: "Cholesterol", code: "IAB7-12" },
  { category: "Chronic Fatigue Syndrome", code: "IAB7-13" },
  { category: "Chronic Pain", code: "IAB7-14" },
  { category: "Cold & Flu", code: "IAB7-15" },
  { category: "Deafness", code: "IAB7-16" },
  { category: "Dental Care", code: "IAB7-17" },
  { category: "Depression", code: "IAB7-18" },
  { category: "Dermatology", code: "IAB7-19" },
  { category: "Diabetes", code: "IAB7-20" },
  { category: "Epilepsy", code: "IAB7-21" },
  { category: "GERD/Acid Reflux", code: "IAB7-22" },
  { category: "Headaches/Migraines", code: "IAB7-23" },
  { category: "Heart Disease", code: "IAB7-24" },
  { category: "Herbs for Health", code: "IAB7-25" },
  { category: "Holistic Healing", code: "IAB7-26" },
  { category: "IBS/Crohn’s Disease", code: "IAB7-27" },
  { category: "Incest/Abuse Support", code: "IAB7-28" },
  { category: "Incontinence", code: "IAB7-29" },
  { category: "Infertility", code: "IAB7-30" },
  { category: "Men’s Health", code: "IAB7-31" },
  { category: "Nutrition", code: "IAB7-32" },
  { category: "Orthopedics", code: "IAB7-33" },
  { category: "Panic/Anxiety Disorders", code: "IAB7-34" },
  { category: "Pediatrics", code: "IAB7-35" },
  { category: "Physical Therapy", code: "IAB7-36" },
  { category: "Psychology/Psychiatry", code: "IAB7-37" },
  { category: "Senior Health", code: "IAB7-38" },
  { category: "Sexuality", code: "IAB7-39" },
  { category: "Sleep Disorders", code: "IAB7-40" },
  { category: "Smoking Cessation", code: "IAB7-41" },
  { category: "Substance Abuse", code: "IAB7-42" },
  { category: "Thyroid Disease", code: "IAB7-43" },
  { category: "Weight Loss", code: "IAB7-44" },
  { category: "Women’s Health", code: "IAB7-45" },
  { category: "American Cuisine", code: "IAB8-1" },
  { category: "Barbecues & Grilling", code: "IAB8-2" },
  { category: "Cajun/Creole", code: "IAB8-3" },
  { category: "Chinese Cuisine", code: "IAB8-4" },
  { category: "Cocktails/Beer", code: "IAB8-5" },
  { category: "Coffee/Tea", code: "IAB8-6" },
  { category: "Cuisine-Specific", code: "IAB8-7" },
  { category: "Desserts & Baking", code: "IAB8-8" },
  { category: "Dining Out", code: "IAB8-9" },
  { category: "Food Allergies", code: "IAB8-10" },
  { category: "French Cuisine", code: "IAB8-11" },
  { category: "Health/Lowfat Cooking", code: "IAB8-12" },
  { category: "Italian Cuisine", code: "IAB8-13" },
  { category: "Japanese Cuisine", code: "IAB8-14" },
  { category: "Mexican Cuisine", code: "IAB8-15" },
  { category: "Vegan", code: "IAB8-16" },
  { category: "Vegetarian", code: "IAB8-17" },
  { category: "Wine", code: "IAB8-18" },
  { category: "Art/Technology", code: "IAB9-1" },
  { category: "Arts & Crafts", code: "IAB9-2" },
  { category: "Beadwork", code: "IAB9-3" },
  { category: "Birdwatching", code: "IAB9-4" },
  { category: "Board Games/Puzzles", code: "IAB9-5" },
  { category: "Candle & Soap Making", code: "IAB9-6" },
  { category: "Card Games", code: "IAB9-7" },
  { category: "Chess", code: "IAB9-8" },
  { category: "Cigars", code: "IAB9-9" },
  { category: "Collecting", code: "IAB9-10" },
  { category: "Comic Books", code: "IAB9-11" },
  { category: "Drawing/Sketching", code: "IAB9-12" },
  { category: "Freelance Writing", code: "IAB9-13" },
  { category: "Genealogy", code: "IAB9-14" },
  { category: "Getting Published", code: "IAB9-15" },
  { category: "Guitar", code: "IAB9-16" },
  { category: "Home Recording", code: "IAB9-17" },
  { category: "Investors & Patents", code: "IAB9-18" },
  { category: "Jewelry Making", code: "IAB9-19" },
  { category: "Magic & Illusion", code: "IAB9-20" },
  { category: "Needlework", code: "IAB9-21" },
  { category: "Painting", code: "IAB9-22" },
  { category: "Photography", code: "IAB9-23" },
  { category: "Radio", code: "IAB9-24" },
  { category: "Roleplaying Games", code: "IAB9-25" },
  { category: "Sci-Fi & Fantasy", code: "IAB9-26" },
  { category: "Scrapbooking", code: "IAB9-27" },
  { category: "Screenwriting", code: "IAB9-28" },
  { category: "Stamps & Coins", code: "IAB9-29" },
  { category: "Video & Computer Games", code: "IAB9-30" },
  { category: "Woodworking", code: "IAB9-31" },
  { category: "Appliances", code: "IAB10-1" },
  { category: "Entertaining", code: "IAB10-2" },
  { category: "Environmental Safety", code: "IAB10-3" },
  { category: "Gardening", code: "IAB10-4" },
  { category: "Home Repair", code: "IAB10-5" },
  { category: "Home Theater", code: "IAB10-6" },
  { category: "Interior Decorating", code: "IAB10-7" },
  { category: "Landscaping", code: "IAB10-8" },
  { category: "Remodeling & Construction", code: "IAB10-9" },
  { category: "Immigration", code: "IAB11-1" },
  { category: "Legal Issues", code: "IAB11-2" },
  { category: "U.S. Government Resources", code: "IAB11-3" },
  { category: "Politics", code: "IAB11-4" },
  { category: "Commentary", code: "IAB11-5" },
  { category: "International News", code: "IAB12-1" },
  { category: "National News", code: "IAB12-2" },
  { category: "Local News", code: "IAB12-3" },
  { category: "Beginning Investing", code: "IAB13-1" },
  { category: "Credit/Debt & Loans", code: "IAB13-2" },
  { category: "Financial News", code: "IAB13-3" },
  { category: "Financial Planning", code: "IAB13-4" },
  { category: "Hedge Fund", code: "IAB13-5" },
  { category: "Insurance", code: "IAB13-6" },
  { category: "Investing", code: "IAB13-7" },
  { category: "Mutual Funds", code: "IAB13-8" },
  { category: "Options", code: "IAB13-9" },
  { category: "Retirement Planning", code: "IAB13-10" },
  { category: "Stocks", code: "IAB13-11" },
  { category: "Tax Planning", code: "IAB13-12" },
  { category: "Dating", code: "IAB14-1" },
  { category: "Divorce Support", code: "IAB14-2" },
  { category: "Gay Life", code: "IAB14-3" },
  { category: "Marriage", code: "IAB14-4" },
  { category: "Senior Living", code: "IAB14-5" },
  { category: "Teens", code: "IAB14-6" },
  { category: "Weddings", code: "IAB14-7" },
  { category: "Ethnic Specific", code: "IAB14-8" },
  { category: "Astrology", code: "IAB15-1" },
  { category: "Biology", code: "IAB15-2" },
  { category: "Chemistry", code: "IAB15-3" },
  { category: "Geology", code: "IAB15-4" },
  { category: "Paranormal Phenomena", code: "IAB15-5" },
  { category: "Physics", code: "IAB15-6" },
  { category: "Space/Astronomy", code: "IAB15-7" },
  { category: "Geography", code: "IAB15-8" },
  { category: "Botany", code: "IAB15-9" },
  { category: "Weather", code: "IAB15-10" },
  { category: "Aquariums", code: "IAB16-1" },
  { category: "Birds", code: "IAB16-2" },
  { category: "Cats", code: "IAB16-3" },
  { category: "Dogs", code: "IAB16-4" },
  { category: "Large Animals", code: "IAB16-5" },
  { category: "Reptiles", code: "IAB16-6" },
  { category: "Veterinary Medicine", code: "IAB16-7" },
  { category: "Auto Racing", code: "IAB17-1" },
  { category: "Baseball", code: "IAB17-2" },
  { category: "Bicycling", code: "IAB17-3" },
  { category: "Bodybuilding", code: "IAB17-4" },
  { category: "Boxing", code: "IAB17-5" },
  { category: "Canoeing/Kayaking", code: "IAB17-6" },
  { category: "Cheerleading", code: "IAB17-7" },
  { category: "Climbing", code: "IAB17-8" },
  { category: "Cricket", code: "IAB17-9" },
  { category: "Figure Skating", code: "IAB17-10" },
  { category: "Fly Fishing", code: "IAB17-11" },
  { category: "Football", code: "IAB17-12" },
  { category: "Freshwater Fishing", code: "IAB17-13" },
  { category: "Game & Fish", code: "IAB17-14" },
  { category: "Golf", code: "IAB17-15" },
  { category: "Horse Racing", code: "IAB17-16" },
  { category: "Horses", code: "IAB17-17" },
  { category: "Hunting/Shooting", code: "IAB17-18" },
  { category: "Inline Skating", code: "IAB17-19" },
  { category: "Martial Arts", code: "IAB17-20" },
  { category: "Mountain Biking", code: "IAB17-21" },
  { category: "NASCAR Racing", code: "IAB17-22" },
  { category: "Olympics", code: "IAB17-23" },
  { category: "Paintball", code: "IAB17-24" },
  { category: "Power & Motorcycles", code: "IAB17-25" },
  { category: "Pro Basketball", code: "IAB17-26" },
  { category: "Pro Ice Hockey", code: "IAB17-27" },
  { category: "Rodeo", code: "IAB17-28" },
  { category: "Rugby", code: "IAB17-29" },
  { category: "Running/Jogging", code: "IAB17-30" },
  { category: "Sailing", code: "IAB17-31" },
  { category: "Saltwater Fishing", code: "IAB17-32" },
  { category: "Scuba Diving", code: "IAB17-33" },
  { category: "Skateboarding", code: "IAB17-34" },
  { category: "Skiing", code: "IAB17-35" },
  { category: "Snowboarding", code: "IAB17-36" },
  { category: "Surfing/Bodyboarding", code: "IAB17-37" },
  { category: "Swimming", code: "IAB17-38" },
  { category: "Table Tennis/Ping-Pong", code: "IAB17-39" },
  { category: "Tennis", code: "IAB17-40" },
  { category: "Volleyball", code: "IAB17-41" },
  { category: "Walking", code: "IAB17-42" },
  { category: "Waterski/Wakeboard", code: "IAB17-43" },
  { category: "World Soccer", code: "IAB17-44" },
  { category: "Beauty", code: "IAB18-1" },
  { category: "Body Art", code: "IAB18-2" },
  { category: "Fashion", code: "IAB18-3" },
  { category: "Jewelry", code: "IAB18-4" },
  { category: "Clothing", code: "IAB18-5" },
  { category: "Accessories", code: "IAB18-6" },
  { category: "3-D Graphics", code: "IAB19-1" },
  { category: "Animation", code: "IAB19-2" },
  { category: "Antivirus Software", code: "IAB19-3" },
  { category: "C/C++", code: "IAB19-4" },
  { category: "Cameras & Camcorders", code: "IAB19-5" },
  { category: "Cell Phones", code: "IAB19-6" },
  { category: "Computer Certification", code: "IAB19-7" },
  { category: "Computer Networking", code: "IAB19-8" },
  { category: "Computer Peripherals", code: "IAB19-9" },
  { category: "Computer Reviews", code: "IAB19-10" },
  { category: "Data Centers", code: "IAB19-11" },
  { category: "Databases", code: "IAB19-12" },
  { category: "Desktop Publishing", code: "IAB19-13" },
  { category: "Desktop Video", code: "IAB19-14" },
  { category: "Email", code: "IAB19-15" },
  { category: "Graphics Software", code: "IAB19-16" },
  { category: "Home Video/DVD", code: "IAB19-17" },
  { category: "Internet Technology", code: "IAB19-18" },
  { category: "Java", code: "IAB19-19" },
  { category: "JavaScript", code: "IAB19-20" },
  { category: "Mac Support", code: "IAB19-21" },
  { category: "MP3/MIDI", code: "IAB19-22" },
  { category: "Net Conferencing", code: "IAB19-23" },
  { category: "Net for Beginners", code: "IAB19-24" },
  { category: "Network Security", code: "IAB19-25" },
  { category: "Palmtops/PDAs", code: "IAB19-26" },
  { category: "PC Support", code: "IAB19-27" },
  { category: "Portable", code: "IAB19-28" },
  { category: "Entertainment", code: "IAB19-29" },
  { category: "Shareware/Freeware", code: "IAB19-30" },
  { category: "Unix", code: "IAB19-31" },
  { category: "Visual Basic", code: "IAB19-32" },
  { category: "Web Clip Art", code: "IAB19-33" },
  { category: "Web Design/HTML", code: "IAB19-34" },
  { category: "Web Search", code: "IAB19-35" },
  { category: "Windows", code: "IAB19-36" },
  { category: "Adventure Travel", code: "IAB20-1" },
  { category: "Africa", code: "IAB20-2" },
  { category: "Air Travel", code: "IAB20-3" },
  { category: "Australia & New Zealand", code: "IAB20-4" },
  { category: "Bed & Breakfasts", code: "IAB20-5" },
  { category: "Budget Travel", code: "IAB20-6" },
  { category: "Business Travel", code: "IAB20-7" },
  { category: "By US Locale", code: "IAB20-8" },
  { category: "Camping", code: "IAB20-9" },
  { category: "Canada", code: "IAB20-10" },
  { category: "Caribbean", code: "IAB20-11" },
  { category: "Cruises", code: "IAB20-12" },
  { category: "Eastern Europe", code: "IAB20-13" },
  { category: "Europe", code: "IAB20-14" },
  { category: "France", code: "IAB20-15" },
  { category: "Greece", code: "IAB20-16" },
  { category: "Honeymoons/Getaways", code: "IAB20-17" },
  { category: "Hotels", code: "IAB20-18" },
  { category: "Italy", code: "IAB20-19" },
  { category: "Japan", code: "IAB20-20" },
  { category: "Mexico & Central America", code: "IAB20-21" },
  { category: "National Parks", code: "IAB20-22" },
  { category: "South America", code: "IAB20-23" },
  { category: "Spas", code: "IAB20-24" },
  { category: "Theme Parks", code: "IAB20-25" },
  { category: "Traveling with Kids", code: "IAB20-26" },
  { category: "United Kingdom", code: "IAB20-27" },
  { category: "Apartments", code: "IAB21-1" },
  { category: "Architects", code: "IAB21-2" },
  { category: "Buying/Selling Homes", code: "IAB21-3" },
  { category: "Contests & Freebies", code: "IAB22-1" },
  { category: "Couponing", code: "IAB22-2" },
  { category: "Comparison", code: "IAB22-3" },
  { category: "Engines", code: "IAB22-4" },
  { category: "Alternative Religions", code: "IAB23-1" },
  { category: "Atheism/Agnosticism", code: "IAB23-2" },
  { category: "Buddhism", code: "IAB23-3" },
  { category: "Catholicism", code: "IAB23-4" },
  { category: "Christianity", code: "IAB23-5" },
  { category: "Hinduism", code: "IAB23-6" },
  { category: "Islam", code: "IAB23-7" },
  { category: "Judaism", code: "IAB23-8" },
  { category: "Latter-Day Saints", code: "IAB23-9" },
  { category: "Pagan/Wiccan", code: "IAB23-10" },
  { category: "Unmoderated UGC", code: "IAB25-1" },
  { category: "Extreme Graphic/Explicit Violence", code: "IAB25-2" },
  { category: "Pornography", code: "IAB25-3" },
  { category: "Profane Content", code: "IAB25-4" },
  { category: "Hate Content", code: "IAB25-5" },
  { category: "Under Construction", code: "IAB25-6" },
  { category: "Incentivized", code: "IAB25-7" },
  { category: "Illegal Content", code: "IAB26-1" },
  { category: "Warez", code: "IAB26-2" },
  { category: "Spyware/Malware", code: "IAB26-3" },
  { category: "Copyright Infringement", code: "IAB26-4" },
];

export const defaultIabCategory = {
  category: "Alternative Medicine",
  code: "IAB7-5",
};

export const TABLE_ORDER_ASC = "ascend";
export const TABLE_ORDER_DESC = "descend";

export const defaultCPM = {
  cpm: 7,
};

export const jsTrackerScript =
  '<script type="text/javascript">!function(e,a,c,i,n,o,t){e[n]||(e.GlobalSnowplowNamespace=e.GlobalSnowplowNamespace||[],e.GlobalSnowplowNamespace.push(n),e[n]=function(){(e[n].q=e[n].q||[]).push(arguments)},e[n].q=e[n].q||[],o=a.createElement(c),t=a.getElementsByTagName(c)[0],o.async=1,o.src="//dm2q9qfzyjfox.cloudfront.net/sp.js",t.parentNode.insertBefore(o,t))}(window,document,"script",0,"LiquidM");var unstruct={schema:"iglu:com.mediajel.events/ad_impression/jsonschema/1-0-1",data:{advertiserId:"{CUSTOMER_ID} ",insertionOrder:"{Campaign_ID}",lineItemId:"LiquidM_MAIN",creativeId:"{AD_NAME}",publisherId:"{PUBLISHER_ID}",publisherName:"{PUBLISHER_NAME}",siteId:"{APP_DOMAIN}",siteName:"{SITE_NAME}",appId:"{APP_STOREURL}",appName:"{APP_NAME}"}},mjcx=[],cCx={schema:"iglu:com.mediajel.contexts/client/jsonschema/1-0-0",data:{clientId:"loggedinorg-lanchedby"}},cCx2={schema:"iglu:com.mediajel.contexts/campaign/jsonschema/1-0-0",data:{campaignOrderId:"LiquidM_campaign_orders"}},cCx3={schema:"iglu:com.mediajel.contexts/identities/jsonschema/1-0-0",data:{DSP:"LiquidM",GAID:"{GAID}",GAID_MD5:"{GAID_MD5 }",GAID_SHA1:"{GAID_SHA1}",IDFA:"{IDFA}",IDFA_MD5:"{IDFA_MD5}",IDFA_SHA1:"{IDFA_SHA1}",DSPIDENTIFIER:"",DEVICEID:""}};mjcx.push(cCx),mjcx.push(cCx2),mjcx.push(cCx3),LiquidM("newTracker","cf","collector.dmp.cnna.io",{appId:"LiquidM",discoverRootDomain:!0,stateStorageStrategy:"localStorage"}),LiquidM("trackSelfDescribingEvent",unstruct,mjcx),setTimeout(function(e,a,c,i,n,o,t){e[n]||(e.GlobalSnowplowNamespace=e.GlobalSnowplowNamespace||[],e.GlobalSnowplowNamespace.push(n),e[n]=function(){(e[n].q=e[n].q||[]).push(arguments)},e[n].q=e[n].q||[],o=a.createElement(c),a.getElementsByTagName(c)[0],o.decoding="async",o.src=i,o.height=0,o.width=0,o.border=0)},1e3,window,document,"img","https://sync.dmp.cnna.io/cs","Sync");</script>';
export const roasFormat = [
  { name: "Percent", key: "PERCENT" },
  { name: "Multiple", key: "FLAT" }, //Flat will remain as the key but multiple on display.
  { name: "Currency", key: "DOLLAR" },
];
export const currencyFormat = [
  {
    name: "US Dollar",
    key: "USD",
    symbol: "$",
    conversionValue: 1,
    display: "$", //Temporary use the $ symbol until the current implementation has been fully checked to prevent confusion on frontend
  },
  {
    name: "Canadian Dollar",
    key: "CAD",
    symbol: "$",
    conversionValue: 1,
    display: "CAD ",
  },
  {
    name: "Thai Baht",
    key: "THB",
    symbol: "฿",
    conversionValue: 1,
    display: "THB ",
  },
];

export const attributionWindowSelections = [
  { name: "30 Days", key: 30 },
  { name: "20 Days", key: 20 },
  { name: "15 Days", key: 15 },
  { name: "10 Days", key: 10 },
  { name: "7 Days", key: 7 },
  { name: "5 Days", key: 5 },
  { name: "2 Days", key: 2 },
  { name: "1 Day", key: 1 },
];

export const attributionTypeSelections = [
  { name: "All", key: "ALL" },
  { name: "Cookies", key: "COOKIE" },
  { name: "IP Address", key: "IP" },
  { name: "Fingerprint", key: "FINGERPRINT" },
  { name: "Device Fingerprint", key: "DEVICE_FINGERPRINT" },
];

export const sendEmailFrequency = ["WEEKLY"]; // WEEKLY FOR NOW;

export const sendEmailDayOfWeek = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
];

export const defaultRoasFormat = roasFormat[0];
export const defaultCurrencyFormat = currencyFormat[0];

export const defaultAttributionWindow = attributionWindowSelections[0]; //Default attribution window 30 days

export const AmplifyDefault = {
  budget: 500 * 12,
  category: "IAB7-5",
  kpiGoal: 0,
  kpi: "CPM",
  status: "PENDING",
  geoTargets: [],
  attributionTargets: [],
  devices: [],
  brands: [],
  age: [],
  income: [],
  targetAudiences: [],
  tags: [],
  isRegulated: true,
  type: "DISPLAY",
  attributionWindow: 30,
  cpm: 7,
  awarenessCampaign: true,
  region: [],
  transactionsConfig: {
    create: {
      traffId: null,
      gtmAccess: null,
      gtmStatus: null,
      tracksBasketItems: null,
    },
  },
};

export const defaultDealIds = {
  DISPLAY: {
    USA: ["332730376849", "868058231105", "475703366494", "393369179231"],
    CANADA: ["367640989143"],
    UK: [],
    SPAIN: [],
  },
  EXTERNAL: {
    USA: ["332730376849", "868058231105", "475703366494", "393369179231"],
    CANADA: ["367640989143"],
    UK: [],
    SPAIN: [],
  },
  VIDEO: {
    USA: ["972343505786"],
  },
};

export const validDimensions = [
  { width: 300, height: 250 },
  { width: 320, height: 50 },
  { width: 728, height: 90 },
  { width: 160, height: 600 },
  { width: 300, height: 600 },
  { width: 300, height: 50 },
  { width: 970, height: 250 },
  { width: 320, height: 480 },
  { width: 336, height: 280 },
  { width: 320, height: 100 },
  { width: 1280, height: 960 },
  { width: 1080, height: 1920 },
  { width: 1920, height: 1080 },
];

export const invalidImageDimensionErrorMessage = `Image must have the following width and height format: ${validDimensions
  .map(d => `(${d.width}x${d.height})`)
  .join(", ")}`;

export const stepsKeyValue = {
  "1": "basics",
  "2": "targeting",
  "3": "creatives",
  "4": "attributions",
};

export const advertisers = [
  { value: "LIQUIDM", appId: "LiquidM", label: "LiquidM" },
  { value: "NEXXEN", appId: "NEXXEN", label: "Nexxen" },
  { value: "BKO", appId: "BKO", label: "BKO" },
];

export const defaultOnspotWalkInReportData = campaign => ({
  geoStartDate: campaign.startDate,
  geoEndate: campaign.endDate,
  attribution: {
    count: 0,
    frequency: {
      one: 0,
      two: 0,
      three: 0,
      fourToSeven: 0,
      eightToFifteen: 0,
      SixteenToTwentyFive: 0,
      TwentySixOrMore: 0,
    },
    dayOfWeek: [
      {
        day: "MONDAY",
        count: 0,
      },
      {
        day: "TUESDAY",
        count: 0,
      },
      {
        day: "WEDNESDAY",
        count: 0,
      },
      {
        day: "THURSDAY",
        count: 0,
      },
      {
        day: "FRIDAY",
        count: 0,
      },
      {
        day: "SATURDAY",
        count: 0,
      },
      {
        day: "SUNDAY",
        count: 0,
      },
    ],
    hourOfDay: [
      {
        hour: "0",
        count: 0,
      },
      {
        hour: "1",
        count: 0,
      },
      {
        hour: "2",
        count: 0,
      },
      {
        hour: "3",
        count: 0,
      },
      {
        hour: "4",
        count: 0,
      },
      {
        hour: "5",
        count: 0,
      },
      {
        hour: "6",
        count: 0,
      },
      {
        hour: "7",
        count: 0,
      },
      {
        hour: "8",
        count: 0,
      },
      {
        hour: "9",
        count: 0,
      },
      {
        hour: "10",
        count: 0,
      },
      {
        hour: "11",
        count: 0,
      },
      {
        hour: "12",
        count: 0,
      },
      {
        hour: "13",
        count: 0,
      },
      {
        hour: "14",
        count: 0,
      },
      {
        hour: "15",
        count: 0,
      },
      {
        hour: "16",
        count: 0,
      },
      {
        hour: "17",
        count: 0,
      },
      {
        hour: "18",
        count: 0,
      },
      {
        hour: "19",
        count: 0,
      },
      {
        hour: "20",
        count: 0,
      },
      {
        hour: "21",
        count: 0,
      },
      {
        hour: "22",
        count: 0,
      },
      {
        hour: "23",
        count: 0,
      },
    ],
  },
  observations: {
    uniqueCount: 0,
    nonUniqueCount: 0,
    byDate: [],
    byDay: [
      {
        day: "Sun",
        count: 0,
      },
      {
        day: "Mon",
        count: 0,
      },
      {
        day: "Tue",
        count: 0,
      },
      {
        day: "Wed",
        count: 0,
      },
      {
        day: "Thu",
        count: 0,
      },
      {
        day: "Fri",
        count: 0,
      },
      {
        day: "Sat",
        count: 0,
      },
    ],
    byHour: [
      {
        hour: 0,
        count: 0,
      },
      {
        hour: 1,
        count: 0,
      },
      {
        hour: 2,
        count: 0,
      },
      {
        hour: 3,
        count: 0,
      },
      {
        hour: 4,
        count: 0,
      },
      {
        hour: 5,
        count: 0,
      },
      {
        hour: 6,
        count: 0,
      },
      {
        hour: 7,
        count: 0,
      },
      {
        hour: 8,
        count: 0,
      },
      {
        hour: 9,
        count: 0,
      },
      {
        hour: 10,
        count: 0,
      },
      {
        hour: 11,
        count: 0,
      },
      {
        hour: 12,
        count: 0,
      },
      {
        hour: 13,
        count: 0,
      },
      {
        hour: 14,
        count: 0,
      },
      {
        hour: 15,
        count: 0,
      },
      {
        hour: 16,
        count: 0,
      },
      {
        hour: 17,
        count: 0,
      },
      {
        hour: 18,
        count: 0,
      },
      {
        hour: 19,
        count: 0,
      },
      {
        hour: 20,
        count: 0,
      },
      {
        hour: 21,
        count: 0,
      },
      {
        hour: 22,
        count: 0,
      },
      {
        hour: 23,
        count: 0,
      },
    ],
  },
});

export const onboardingItems = [
  {
    title: "Meet Your MJ Team",
    description: "Your MediaJel support crew and contact info",
    link:
      "https://docs.google.com/document/d/1_-UVV8ky_jfSn8DMbLxwz8hTD4q3e7jHEsontR3gft4/edit#heading=h.owxyyj5bwxaj",
  },
  {
    title: "State-by-State Regulations",
    description: "State advertising regulations resource",
    link:
      "https://drive.google.com/file/d/1jLk3OtcG7WB4jBMcXzp80TI7n38BPZGW/view",
  },
  {
    title: "Programmatic Training",
    description: "Your programmatic 101 training ebook",
    link:
      "https://docs.google.com/document/d/1lyNUTfZpn-qVkAkAOYtWhrXDDqjsZmaY/edit",
  },
  {
    title: "Landing Page Best Practices",
    description: "Tips to increase conversion rates",
    link:
      "https://docs.google.com/document/d/1nQ0PMFY_HNEZciV6UK1dn6JgkDXF6BY9HHPlQPApjlE/edit#heading=h.n33c9rbdgepr",
  },
  {
    title: "Rich Media Gallery",
    description: "Ad unit sizing and info",
    link:
      "https://coda.io/d/MediaJel-Creative-Library_dfggEsZBT67/MediaJel-Creative-Library_suwUQ#_luYyS",
  },
  {
    title: "Pre-Launch Checklist",
    description: "Vetting opps for successful ad campaigns",
    link:
      "https://docs.google.com/document/d/14oroyoVt7BTUBKIMvUr-d8tFm1PsgqiON386rz8-XN8/edit#heading=h.4an6jjcodip",
  },
  {
    title: "Display Ad Campaign Parameters",
    description: "Setting up successful display ad campaigns",
    link:
      "https://docs.google.com/document/d/1y12V9XKEitDZGMbtKSmexNtWlRMjJeWGLPIS23sFuFg/edit#heading=h.i0rer4onm387",
  },
  {
    title: "Weedmaps Downsides",
    description: "Third-party platform downsides",
    link:
      "https://docs.google.com/document/d/1cl92ULU-NRbi0PTNoT42eP7HFd-DaXIl2V5SWVFJcTg/edit#heading=h.g3g5ynady1xf",
  },
  {
    title: "Data Collection + User Identity Graph",
    description: "Methods of user identification for sales attribution",
    link:
      "https://docs.google.com/document/d/1784cjj_U91jCC1ggIk9Zwj_rE3BwsMijVKaQY93H47Q/edit#heading=h.8or3pnk9oo9x",
  },
  {
    title: "Walk-In Data: Downsides",
    description: "How privacy changes have impacted walk-in attribution",
    link:
      "https://docs.google.com/document/d/1shs-1fsnbPZKd-KufdvF0kwMoSNcpu69FNkLD33IKtk/edit#heading=h.hiqizi822eoj",
  },
  {
    title: "Handling Objections",
    description: "Top tips for best handling sales objections",
    link:
      "https://docs.google.com/document/d/1Lf4z8YaJeESdzxfBotewnPDZJECtIRCdKMAxZVTmxhY/edit#heading=h.bel2dx8995wb",
  },
  {
    title: "Discovery / Onboarding",
    description: "Questions for vetting clients",
    link:
      "https://docs.google.com/document/d/1vP654LAfWzlddpIMhl82cZbewUUZYb_lo-SbpwJogPg/edit",
  },
];

export const learningHubItems = [
  {
    title: "Revenue Attribution Models",
    description: "Revenue Attribution 101",
    link:
      "https://docs.google.com/document/d/1xl21aWt5fKaw5xrI-tBrWzB6J6CIulM7ata7VaASHfI/edit",
  },
  {
    title: "OTT / CTV Advertising",
    description: "A comprehensive comparison",
    link:
      "https://docs.google.com/document/d/1xlA3groRvdpjVA-0yhCJtZLt6kKyLGE3djIHKwFclf4/edit#heading=h.r2pc26o20jgq",
  },
  {
    title: "Choosing the Right White-label Programmatic Advertising Partner",
    description: "Everything you need to know",
    link:
      "https://docs.google.com/document/d/1wWclndFTMoZHEVX-RzP--i46gjt1Q3e_2XwUCKAoB6A/edit",
  },
  {
    title: "Benefits of Self-Service DSP for Regulated Industries",
    description: "Everything you need to know",
    link:
      "https://docs.google.com/document/d/1it07ILx2MQ6vBs82saz5npip23MGTRHrqTzEyAtsghA/edit#heading=h.azy9bfb289h1",
  },
];

export const providers = [
  {
    value: "bigcommerce",
    label: "Bigcommerce",
    legacyCtr: "BIGCOMMERCE",
  },
  { value: "buddi", label: "Buddi", legacyCtr: "BUDDI" },
  { value: "dispense", label: "Dispense", legacyCtr: "DISPENSE" },
  {
    value: "dutchie-iframe",
    label: "Dutchie Iframe",
    legacyCtr: "DUTCHIEJS",
  },
  {
    value: "dutchieplus",
    label: "Dutchie Plus",
    legacyCtr: "DUTCHIEPLUS",
  },
  {
    value: "dutchie-subdomain",
    label: "Dutchie Subdomain",
    legacyCtr: "DUTCHIE",
  },
  { value: "ecwid", label: "Ecwid", legacyCtr: "ECWID" },
  { value: "grassdoor", label: "Grassdoor", legacyCtr: "GRASSDOOR" },
  { value: "greenrush", label: "Greenrush", legacyCtr: "GREENRUSH" },
  { value: "jane", label: "Jane", legacyCtr: "JANE" },
  { value: "lightspeed", label: "Lightspeed", legacyCtr: "LIGHTSPEED" },
  { value: "magento", label: "Magento", legacyCtr: "MAGENTO" },
  { value: "meadow", label: "Meadow", legacyCtr: "MEADOW" },
  { value: "olla", label: "Olla", legacyCtr: "OLLA" },
  { value: "shopify", label: "Shopify", legacyCtr: "SHOPIFYUNIVERSAL" },
  {
    value: "sticky-leaf",
    label: "Sticky Leaf",
    legacyCtr: "STICKYLEAF",
  },
  { value: "square", label: "Square", legacyCtr: "SQUARE" },
  { value: "thirdparty", label: "Thirdparty", legacyCtr: "THIRDPARTY" },
  { value: "tymber", label: "Tymber", legacyCtr: "TYMBER" },
  { value: "wefunder", label: "Wefunder", legacyCtr: "WEFUNDER" },
  { value: "webjoint", label: "Webjoint", legacyCtr: "WEBJOINT" },
  {
    value: "woocommerce",
    label: "Woocommerce",
    legacyCtr: "WOOCOMMERCE",
  },
  { value: "yotpo", label: "Yotpo", legacyCtr: "YOTPO" },
  { value: "no-cart", label: "No Cart", legacyCtr: "NOCART" },
];

export const countryMapping = {
  US: "USA",
  CA: "CANADA",
  ES: "SPAIN",
  UK: "UNITED_KINGDOM",
};
