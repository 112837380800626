import React from "react";
import { Card, Col, Divider, message, Row, Space, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
const { Text, Paragraph } = Typography;
const DetailsCard = ({ details, onDisableEnableTag, loading }) => {
  return (
    <Card title={<Text strong>TAG DETAILS</Text>}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text type="secondary">Tag ID</Text>
            <Space direction="horizontal">
              <Text
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  width: "100%",
                }}
              >
                {details.appId}
              </Text>
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(details.appId);
                  message.info("Tag ID copied to clipboard");
                }}
              />
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text type="secondary">Advertiser</Text>
            <Text
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                width: "100%",
              }}
            >
              {details.advertiser}
            </Text>
          </Space>
        </Col>
        <Col span={24}>
          <Row align="top">
            <Col xs={24} sm={12} md={4}>
              <Space direction="vertical" size={0}>
                <Text type="secondary">Cart Platform</Text>
                <Text
                  style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    width: "100%",
                  }}
                >
                  {details.cart}
                </Text>
              </Space>
            </Col>
            <Col xs={0} sm={1} md={1}>
              <Divider type="vertical" style={{ height: "8em" }} />
            </Col>
            <Col xs={24} sm={11} md={10}>
              <Space direction="vertical">
                <Text type="secondary">Supported Measurements</Text>
                <Paragraph>
                  <ul>
                    {details.supportMeasurements.pageViews && (
                      <li>
                        <Text>Page Views</Text>
                      </li>
                    )}
                    {details.supportMeasurements.transactions && (
                      <li>
                        <Text>Transactions</Text>
                      </li>
                    )}
                    {details.supportMeasurements.basketItems && (
                      <>
                        <li>
                          <Text>Add to Cart</Text>
                        </li>
                        <li>
                          <Text>Remove from Cart</Text>
                        </li>
                        <li>
                          <Text>Abandoned Cart</Text>
                        </li>
                      </>
                    )}
                    {details.supportMeasurements.signups && (
                      <li>
                        <Text>Sign ups</Text>
                      </li>
                    )}
                  </ul>
                </Paragraph>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DetailsCard;
