import React, { useState } from "react";
import { Card, Row, Col, Input, Button, Table } from "antd";

const SelectTagsModal = ({
  formattedTags,
  onSelectTags,
  tagsColumns,
  rowType = "checkbox", // TEMP: Allows to use radio button in publisher and checkbox in advertiser
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableTags, setTableTags] = useState(
    formattedTags.filter(tag => !tag.isOwned)
  );

  const handleSearch = value => {
    if (value && value !== "") {
      const filteredTags = formattedTags.filter(
        tag =>
          tag.label.toLowerCase().includes(value.toLowerCase()) && !tag.isOwned
      );
      setTableTags(filteredTags);
    } else {
      setTableTags(formattedTags.filter(tag => !tag.isOwned));
    }
  };

  const handleAddSelections = () => {
    const selected = tableTags
      .filter(tag => selectedRowKeys.includes(tag.value))
      .map(tag => ({
        ...tag,
        measuredEvents: [
          tag.pageViews > 0 ? "PAGE_VIEWS" : null,
          tag.transactions > 0 ? "TRANSACTIONS" : null,
          tag.signups > 0 ? "SIGN_UPS" : null,
          tag.basketItems > 0 ? "BASKET_ITEMS" : null,
        ].filter(Boolean),
      }));
    onSelectTags(selected);
  };

  return (
    <Card
      title="Tags"
      extra={
        <Row gutter={16}>
          <Col>
            <Input.Search
              placeholder="Search Tags"
              allowClear
              onSearch={handleSearch}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleAddSelections}
              disabled={selectedRowKeys.length === 0}
              style={{ marginRight: "20px" }}
            >
              Add Selections
            </Button>
          </Col>
        </Row>
      }
    >
      <Table
        dataSource={tableTags}
        columns={tagsColumns}
        rowKey={record => record.value}
        scroll={{ x: "100%" }}
        size="middle"
        rowSelection={{
          type: rowType,
          selectedRowKeys,
          onChange: newSelectedRowKeys => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
      />
    </Card>
  );
};

export default SelectTagsModal;
